import React, { useState, useEffect, useContext } from 'react';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useIsAuthenticated } from "@azure/msal-react";
import { CandidateContext } from '../../Context/CandidateContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CandidatePersonalSummary = ({ candidateID, candidateData }) => {
    const isAuthenticated = useIsAuthenticated();
    const { refetchCandidateDetails } = useContext(CandidateContext);
    const { token, idTokenClaims } = useContext(TokenContext);
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const { showAlert } = useContext(AlertContext);
    const baseUrl = getApiBaseUrl();

    const [formData, setFormData] = useState({
        Value: "",
        SummaryID: 0,
    });
    const [errors, setErrors] = useState({ Value: "" });

    useEffect(() => {
        if (!isAuthenticated) {
            return <div>Please log in to access the dashboard.</div>;
        }

        if (candidateData) {
            setFormData({
                Value: candidateData.Value || "",
                SummaryID: candidateData.SummaryID || 0,
            });
            setEditing(false);
        } else if (candidateData === null) {
            setEditing(true);
        }
    }, [candidateData]);

    const handleInputChange = (value) => {
        setFormData((prevFormData) => ({ ...prevFormData, Value: value }));
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.Value || formData.Value.replace(/<[^>]*>?/gm, '').trim() === "") {
            newErrors.Value = 'Summary is required.';
        } else if (formData.Value.length > 2000) {
            newErrors.Value = 'Summary should not exceed 2000 characters.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;
        if (saving) return;
        
        setSaving(true);
        const dataToPost = {
            Value: formData.Value,
            CandidateID: candidateID,
            SummaryID: formData.SummaryID,
            DateTimeCreated: new Date().toISOString(),
            DateTimeModified: new Date().toISOString(),
            CreatedBy: 'Admin',
            ModifiedBy: 'User',
            IsDeleted: false,
            B2CObjectId: idTokenClaims.oid,
            Status: true,
        };
        
        try {
            const response = await fetch(`${baseUrl}/api/Summary/Upsert`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(dataToPost),
            });
            const responseData = await response.json();
            if (responseData.success) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    SummaryID: responseData.result.SummaryID,
                }));
                showAlert('Success', true);
                refetchCandidateDetails();
            } else {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
        } catch (error) {
            showAlert('Oops, something went wrong. Please try again later.', false);
        } finally {
            setSaving(false);
        }
    };

    return (
        <div className="summary-item stealth-inn">
            {!editing && (
                <div className="edit-icon">
                    <button type="button" className="btn px-2 edit-summary edit-but" onClick={() => setEditing(true)}></button>
                </div>
            )}
            {editing ? (
                <form id="summary-form">
                    <div className="summary-item-edit">
                        <div className="summary-edit card-body-iajj">
                            <ReactQuill theme="snow" value={formData.Value} onChange={handleInputChange} />
                            {errors.Value && <small className="text-danger">{errors.Value}</small>}
                            <div className="col-12 text-end mt-3">
                                <button type="button" className="btn btn-sm btn-primary me-2 save-summary" onClick={handleSubmit} disabled={saving}>
                                    {saving ? 'Saving...' : 'Save'}
                                </button>
                                {candidateData && (
                                    <button type="button" className="btn btn-sm btn-neutral me-2" onClick={() => setEditing(false)}>
                                        Cancel
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            ) : (
                    <div className="summary-item-read bnasdf_asldf">
                    <div className="card-body d-flex">
                        <div dangerouslySetInnerHTML={{ __html: formData.Value }}></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CandidatePersonalSummary;
