import React, { useState, useEffect } from 'react';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useIsAuthenticated } from "@azure/msal-react";

const CandidateBusEducation = ({ candidateData }) => {
    const isAuthenticated = useIsAuthenticated();
    const [educationItems, setEducationItems] = useState(candidateData || []);

    useEffect(() => {
        if (candidateData) {
            setEducationItems(candidateData);
        }
    }, [candidateData]);

    useEffect(() => {
    }, [educationItems]);

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    if (!candidateData) {
        return <SkeletonLoader />;
    }
    return (
        <>
            {educationItems.map((education, index) => {
                return (
                    <div className="education-item" key={education.EducationID}>
                        <div className="bnasdf_asldf education-item-read">
                            <div className="card-body car-dlj-ds d-flex justify-content-between align-items-center">
                                <div>
                                    <h5 className="d-block h5 font-semibold mb-1 text-wrap">{education.Qualification}</h5>
                                    <h3 className="d-block text-sm text-muted text-wrap">
                                        {education.School}  {new Date(education.StartDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' })} to {education.EndDate ? new Date(education.EndDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' }) : "Current"}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}

        </>

    );

}

export default CandidateBusEducation;
