
import { LogLevel } from '@azure/msal-browser';
export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_stacktalent-candidate-uat",
        forgotPassword: "https://talentupuat.b2clogin.com/talentupuat.onmicrosoft.com/B2C_1_talentupuat_cand_su_si_PR",
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_AUTHORITY,
        },
        forgotPassword: {
            authority: process.env.REACT_APP_PASSWORDAUTHORITY,
        },
    },
    authorityDomain: "talentupuat.b2clogin.com",
};

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: process.env.REACT_APP_REDIRECTURI,
        postLogoutRedirectUri: process.env.REACT_APP_REDIRECTLOGOUTURI,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            logLevel: LogLevel.Verbose,
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) return;

                const tag = "[MSAL]";
                switch (level) {
                    case LogLevel.Error:
                        console.error(tag, message);
                        break;
                    case LogLevel.Info:
                        console.info(tag, message);
                        break;
                    case LogLevel.Verbose:
                        console.debug(tag, message);
                        break;
                    case LogLevel.Warning:
                        console.warn(tag, message);
                        break;
                }
            },
        },
    },
};
export const loginRequest = {
    scopes: ["https://talentupuat.onmicrosoft.com/9d7a2773-5f60-4af3-ac89-a8f9ee98250a/candidate.read"]
};

export const resetRequest = {
    scopes: ["openid", "profile"],
    authority: b2cPolicies.authorities.forgotPassword.authority,
};
const tokenRequest = {
    scopes: ["api.read"],
    forceRefresh: false 
};