import React, { useState,useContext } from 'react';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useIsAuthenticated } from "@azure/msal-react";
import { CandidateContext } from '../../Context/CandidateContext';

function SocialAddForm({ updateSocialItems, candidateID, displayResponseMessage, onCancel }) {
    const isAuthenticated = useIsAuthenticated();

    const { token, idTokenClaims } = useContext(TokenContext);
    const [saving, setSaving] = useState(false);
    const { refetchCandidateDetails } = useContext(CandidateContext)
    const { showAlert } = useContext(AlertContext);
    const [formData, setFormData] = useState({
        SocialName: 'Facebook',
        SocialTypeID: '1',
        Link: '',
    });
    const baseUrl = getApiBaseUrl();

    const [errors, setErrors] = useState({
        Link: '',
        SocialName: '',
    });
    const handleInputChange = (event, fieldName) => {
        const { value } = event.target;

        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const handleSocialTypeChange = (event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        setFormData((prevData) => ({
            ...prevData,
            SocialTypeID: event.target.value,
            SocialName: selectedOption.textContent,
        }));
    };


    //Validation
    const validate = () => {
        const newErrors = {};

        // Check if social name is empty
        if (!formData.Link) {
            newErrors.Link = 'Social is required.';
        }

        // Check if social name exceeds 2048 characters
        else if (formData.Link.length > 2048) {
            newErrors.Link = 'URL should not exceed 2048 characters.';
        }

        // Check if social name looks like a URL (basic validation)
        else if (!/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(formData.Link)) {
            newErrors.Link = 'Please provide a valid URL.';
        }

        setErrors(newErrors);
        return Object.values(newErrors).every(error => !error);
    };
    //Submit to API
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            if (saving) {
                return;
            }
            setSaving(true);
            const socialTypeID = parseInt(formData.SocialTypeID);
            const dataToPost = {
                Link: formData.Link,
                SocialName: formData.SocialName,
                SocialTypeID: socialTypeID,
                // Set other properties here
                CandidateID: candidateID,
                DateTimeCreated: new Date().toISOString(),
                DateTimeModified: new Date().toISOString(),
                CreatedBy: 'Admin',
                ModifiedBy: 'User',
                IsDeleted: false,
                B2CObjectId: idTokenClaims.oid,
            };

            try {
                const response = await fetch(
                    `${baseUrl}/api/Social/Upsert`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(dataToPost),
                    }
                );
                const responseData = await response.json();

                if (responseData.success) {
                    showAlert('Success', true);
                    //updateSocialItems(responseData.result);
                    refetchCandidateDetails(); 

                } else {
                    showAlert('Oops, something went wrong. Please try again later.', false);
                }
            }
            catch (error) {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
            finally {
                setSaving(false);
            }
        }
    };
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    return (
        <div className="social-create-form stealth-inn">
            <form onSubmit={handleSubmit}>
                <div className="social-edit card-body-iajj">
                    <input type="hidden" className="form-control" defaultValue={formData.CandidateID} name="candidateID" readOnly></input>
                    <div className="row g-5">
                        <div className="col-md-6">
                            <div>
                                <label className="form-label">Type</label>
                                <select className="form-select" value={formData.SocialTypeID} onChange={handleSocialTypeChange} >
                                    <option value="1">Facebook</option>
                                    <option value="2">Twitter</option>
                                    <option value="3">Instagram</option>
                                    <option value="4">GitHub</option>
                                </select>
                              
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <label className="form-label">Social Link</label>
                                <input className="form-control" type="text" value={formData.Link} onChange={(e) => handleInputChange(e, 'Link')} />
                                {errors.Link && (
                                    <small className="text-danger">{errors.Link}</small>
                                )}
                            </div>
                        </div>

                        <div className="col-12 text-end">
                            <button className="btn btn-sm btn-primary me-2 save-social" type="submit" disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>
                            <button type="button" className="btn btn-sm btn-neutral me-2 cancel-social" onClick={onCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SocialAddForm;
