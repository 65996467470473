import React, { useState, useEffect, useContext } from 'react';
import { TokenContext } from '../../Context/TokenContext';
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useIsAuthenticated } from "@azure/msal-react";
import { CandidateContext } from '../../Context/CandidateContext';
import { useMsal } from "@azure/msal-react";

const CandidatePersonalDetails = ({ candidateID, candidateData }) => {
    const isAuthenticated = useIsAuthenticated();

        const { instance } = useMsal();
    const user = instance.getActiveAccount(); 

    const { token, idTokenClaims } = useContext(TokenContext);
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const { refetchCandidateDetails } = useContext(CandidateContext)
    const { showAlert } = useContext(AlertContext);
    const handleEditClick = () => {
        setEditing(true);
    };
    const baseUrl = getApiBaseUrl();

    const handleCancelClick = () => {
        setEditing(false);
    };
    const [formData, setFormData] = useState({
        CurrentRole: "",
        Citizenship: "",
        Location: "",
        IsOpentoWork: "",
        CandidateDetailID: 0,
    });
    const [errors, setErrors] = useState({
        CurrentRole: "",
        Citizenship: "",
        Location: "",
        IsOpentoWork: "",
    });
    useEffect(() => {
        if (candidateData) {
            setFormData({
                CurrentRole: candidateData.CurrentRole,
                Citizenship: candidateData.Citizenship,
                Location: candidateData.Location,
                IsOpentoWork: candidateData.IsOpentoWork,
                CandidateDetailID: candidateData.CandidateDetailID,
            });
            setEditing(false);
        }
        else if (candidateData === null) {
            // API has returned, but candidateData is null.
            // So, hide skeleton loader and allow the user to edit.
            setEditing(true);
        }
    }, [candidateData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    //Validation
    const validate = () => {
        const newErrors = {
            CurrentRole: formData.CurrentRole ? (
                /^[a-zA-Z\s,'-.|]+$/.test(formData.CurrentRole) // Allows letters, spaces, commas, dashes, pipes, and periods
                    ? (formData.CurrentRole.length > 100
                        ? 'Current Role should not exceed 100 characters.'
                        : '')
                    : 'Current Role contains invalid characters.'
            ) : 'Current Role is required.',

            Citizenship: formData.Citizenship && formData.Citizenship !== "0" ? '' : 'Citizenship is required.',
            Location: formData.Location ? '' : 'Location is required.',
        };

        setErrors(newErrors);
        return Object.values(newErrors).every(error => !error);
    };


    const mapCitizenshipToText = (value) => {
        switch (value) {
            case 1: return "Australian citizen";
            case 2: return "Permanent Resident";
            case 3: return "Family/partner visa with no restrictions"
            case 4: return "Seeking Sponsorship"
            case 5: return "Holiday temporary work visa"
            case 6: return "Temporary visa with no restrictions"
            case 7: return "Temporary visa with restrictions"
            default: return "";
        }
    };

    //Submit to API
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            if (saving) {
                return;
            }
            setSaving(true);
            const dataToPost = {
                CurrentRole: formData.CurrentRole,
                Citizenship: formData.Citizenship,
                Location: formData.Location,
                IsOpentoWork: true,
                // Set other properties here
                CandidateID: candidateID,
                CandidateDetailID: formData.CandidateDetailID,
                DateTimeCreated: new Date().toISOString(),
                DateTimeModified: new Date().toISOString(),
                CreatedBy: 'Admin',
                ModifiedBy: 'User',
                IsDeleted: false,
                B2CObjectId: user.sub,
            };

            try {
                const response = await fetch(
                    `${baseUrl}/api/CandidateDetails/Upsert`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(dataToPost),
                    }
                );
                const responseData = await response.json();

                if (responseData.success) {
                    const updatedCandidateDetailID = responseData.result.CandidateDetailID;
                    refetchCandidateDetails(); 
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        CandidateDetailID: updatedCandidateDetailID,
                    }));
                    showAlert('Success', true);

                } else {
                    showAlert('Oops, something went wrong. Please try again later.', false);
                }
            }
            catch (error) {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
            finally {
                setSaving(false);
            }
        }
    };
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
  

    return (
        <div className="hausld ldfin">
            <div className="Pdetails-item stealth-inn">
                {editing ? (
                    <form id="Pdetails-form">
                        <div className={`Pdetails-item-${editing ? 'edit' : 'read'}`}>
                            <div className="Pdetails-edit card-body-iajj">
                                <input type="hidden" className="form-control" defaultValue={formData.CandidateDetailID} name="CandidateDetailID" readOnly></input>
                                <input type="hidden" className="form-control" defaultValue={candidateID} name="candidateID" readOnly></input>
                                <div className="row g-5">
                                    <div className="col-md-6">
                                        <div>
                                            <label className="form-label">Current Role</label>
                                            <input type="text" className="form-control" value={formData.CurrentRole} name="CurrentRole" onChange={handleInputChange} placeholder="Software Developer"></input>
                                            {errors.CurrentRole && (
                                                <small className="text-danger">{errors.CurrentRole}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div>
                                            <label className="form-label">Citizenship Status</label>
                                            <select className="form-select" value={formData.Citizenship} name="Citizenship" id="CitizenshipSelect" onChange={handleInputChange}>
                                                <option value="0">--Select--</option>
                                                <option value="1">Australian citizen</option>
                                                <option value="2">Permanent Resident</option>
                                                <option value="3">Family/partner visa with no restrictions</option>
                                                <option value="4">Seeking Sponsorship</option>
                                                <option value="5">Holiday temporary work visa</option>
                                                <option value="6">Temporary visa with no restrictions</option>
                                                <option value="7">Temporary visa with restrictions</option>
                                            </select>
                                            {errors.Citizenship && (
                                                <small className="text-danger">{errors.Citizenship}</small>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div>
                                            <label className="form-label">Current Location</label>
                                            <select className="form-select" value={formData.Location} name="Location" id="LocationSelect" onChange={handleInputChange}>
                                                <option value="">--Select--</option>
                                                <option value="ACT">Australian Capital Territory (ACT)</option>
                                                <option value="NSW">New South Wales (NSW)</option>
                                                <option value="NT">Northern Territory (NT)</option>
                                                <option value="QLD">Queensland (QLD)</option>
                                                <option value="SA">South Australia (SA)</option>
                                                <option value="TAS">Tasmania (TAS)</option>
                                                <option value="VIC">Victoria (VIC)</option>
                                                <option value="WA">Western Australia (WA)</option>
                                                <option value="Residing Outside Of Australia">Residing Outside Of Australia</option>
                                            </select>
                                            {errors.Location && (
                                                <small className="text-danger">{errors.Location}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 text-end">
                                        <button type="button" className="btn btn-sm btn-primary me-2 save-Pdetails" id="save-Pdetails" onClick={handleSubmit} disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>
                                        <button type="button" className="btn btn-sm btn-neutral me-2 cancel-Pdetails" onClick={handleCancelClick}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className={`bnasdf_asldf Pdetails-item-${editing ? 'edit' : 'read'}`}>
                        <div className="card-body d-flex">
                            <div className="dgfsdfgsdfg">
                                <p className="">
                                        <span className="title-fleek text-wrap">Current Job Title: </span><span className="Pdetails_cr">{formData.CurrentRole}</span>
                                </p>
                                <p className="">
                                        <span className="title-fleek text-wrap">Current Location: </span> <span className="Pdetails_cl">{formData.Location}</span>
                                </p>
                                <p className="Pdetails_czs">
                                        <span className="title-fleek text-wrap">Citizenship Status: </span> <span className="Pdetails_czs">{mapCitizenshipToText(formData.Citizenship)}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {!editing && (
                    <span className="edit-icon">
                        <button type="button" className="btn px-2 edit-Pdetails edit-but" onClick={handleEditClick}></button>
                    </span>
                )}
            </div>
        </div>
    );
}

export default CandidatePersonalDetails;
