import React, { useState, useContext, useEffect } from 'react';
import { useIsAuthenticated } from "@azure/msal-react";
import Alert from '../../utils/GenAlert';
import { getApiBaseUrl } from '../../utils/Config';
import { TokenContext } from '../../Context/TokenContext';
import { Modal, Button } from 'react-bootstrap'; // Import react-bootstrap
import { CandidateContext } from '../../Context/CandidateContext';
import useProfilePic from '../../hooks/useProfilePic';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useMsal } from "@azure/msal-react";

const Settings = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const user = instance.getActiveAccount();
    const { token, oid } = useContext(TokenContext);
    const [loading, setLoading] = useState(true);
    const { apiData } = useContext(CandidateContext);
    const [saving, setSaving] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSuccess, setAlertSuccess] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [showEmailInput, setShowEmailInput] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const baseUrl = getApiBaseUrl();
    const [preview, setPreview] = useState(null);
    const profilePicUrl = useProfilePic(token);
 
    useEffect(() => {
        if (apiData) {
            setLoading(false);
        }
    }, [apiData]);

    if (loading) {
        return <SkeletonLoader type="bigBox" />;
    }
    const handleDeleteAccount = async () => {
        try {
            // Delete account in your API
            const apiResponse = await fetch(`${baseUrl}/api/Authentication/DeleteAccount`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ b2cObjectId: oid })
            });

            const responseData = await apiResponse.json();
            if (!responseData.success) {
                throw new Error(responseData.message || 'Failed to delete account in the API.');
            }

            setAlertMessage('Account deleted successfully.');
            setAlertSuccess(true);

            // Sign out the user
            setTimeout(() => {
                instance.logoutRedirect({
                    postLogoutRedirectUri: window.location.origin,
                });
            }, 3000);
        } catch (error) {
            console.error('Error deleting account:', error);
            setAlertMessage(error.message || 'Failed to delete account. Please try again later.');
            setAlertSuccess(false);
        } finally {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    };

    const handleEmailChange = async (e) => {
        e.preventDefault();
        try {
            const EmaildataToPost = {
                b2cObjectId: oid,
                newEmailChange: newEmail
            };
            // Update email in your API
            const apiResponse = await fetch(
                `${baseUrl}/api/Authentication/ChangeEmail`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(EmaildataToPost),
                }
            );

            const responseData = await apiResponse.json();
            if (!responseData.success) {
                throw new Error(responseData.message || 'Failed to update email address in the API.');
            }

            setAlertMessage('Email address updated successfully. Please log in with your new email.');
            setAlertSuccess(true);

            // Sign out the user to re-authenticate
            setTimeout(() => {
                instance.logoutRedirect({
                    postLogoutRedirectUri: window.location.origin,
                });
            }, 3000);
        } catch (error) {
            console.error('Error updating email address:', error);
            setAlertMessage(error.message || 'Failed to update email address. Please try again later.');
            setAlertSuccess(false);
        } finally {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    };

    const triggerPasswordResetFlow = () => {
        instance.loginRedirect({
            authority: `https://talentupuat.b2clogin.com/talentupuat.onmicrosoft.com/B2C_1_talentupuat_cand_su_si_PR`,
            redirectUri: `${window.location.origin}/auth/callback`,
        });
    };



    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }


    return (

        <div className="flex-lg-1 h-screen overflow-y-lg-auto profile-lalk_lo">
            <header className="bg-surface-secondary">
                <div className="bg-cover candidate-hero-al" style={{ height: '100px' }}></div>
                <div className="container-fluid max-w-screen-xl">
                    <div className="row g-0">
                        <div className="col-auto mt-n16">
                            <div id="ProfilePic-add-form">
                                <div className={`col-auto image-upload ${profilePicUrl ? 'has-image' : ''}`}>
                                    <input name="File" />
                                    <label htmlFor="file_upload_pp">
                                        {profilePicUrl ? (
                                            <div className="PlaceHolder-Sub-PP">
                                                <img
                                                    id="ProPic"
                                                    src={`${profilePicUrl}?timestamp=${Date.now()}`}
                                                    alt={`${apiData.FirstName} profile picture`}
                                                />
                                            </div>
                                        ) : (
                                            // Show initials if no profile picture
                                            <div className="PlaceHolder-Sub-PP-TX">
                                                {apiData?.FirstName ? (
                                                    <div className="initials-placeholder">
                                                        {apiData.FirstName[0].toUpperCase()}
                                                    </div>
                                                ) : (
                                                    <div className="initials-placeholder"><i class="bi bi-person"></i></div>
                                                )}
                                            </div>
                                        )}
                                    </label>
                                </div>

                            </div>
                        </div>

                        <div className="col ps-4 pt-4">
                            <h1 className="h2">{apiData.FirstName} {apiData.LastName}</h1>
                        </div>
                    </div>
                </div>
            </header>
            <main className="py-6 bg-surface-secondary">
                <div className="container-fluid max-w-screen-md vstack gap-6">
                    <div className="row g-6">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="mb-2 h4">Change Password</h4>
                                    <p className="text-sm text-muted mb-4">Click here to change your password.</p>
                                    <button type="button" className="btn btn-sm btn-primary" onClick={triggerPasswordResetFlow}>Change Password</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="mb-2 h4">Change Email</h4>
                                    <p className="text-sm text-muted mb-4">Changing your email address will require re-validation.</p>
                                    <p>{apiData?.Email || 'No Email Found'}</p>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary"
                                        onClick={() => setShowEmailInput(true)}
                                        disabled={showEmailInput}
                                    >
                                        Change Email
                                    </button>
                                    {showEmailInput && (
                                        <form onSubmit={handleEmailChange} className="mt-3">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="newEmail">New Email Address</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="newEmail"
                                                    value={newEmail}
                                                    onChange={(e) => setNewEmail(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-sm btn-primary">Submit</button>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="text-danger mb-2">Delete Account</h4>
                                    <p className="text-sm text-muted mb-4">Permanently remove your account and all of its contents. This action is not reversible - please be certain.</p>
                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => setIsModalOpen(true)}>Delete my account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showAlert && <Alert message={alertMessage} success={alertSuccess} />}

                <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Account Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure? By deleting your account, you will lose all history and access.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setIsModalOpen(false)}>No, keep my account</Button>
                        <Button variant="danger" onClick={handleDeleteAccount}>Yes, delete my account</Button>
                    </Modal.Footer>
                </Modal>
            </main>
        </div>
    );
};

export default Settings;
