import React, { useState, useEffect,useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CertificateAddForm from '../../pages/Candidate/CertificateAdd';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useIsAuthenticated } from "@azure/msal-react";
import { CandidateContext } from '../../Context/CandidateContext';

const CandidateCertificate = ({ candidateID, candidateData }) => {
    const isAuthenticated = useIsAuthenticated();
    const { token, idTokenClaims } = useContext(TokenContext);
    const [showAddForm, setShowAddForm] = useState(false);
    const [certificateItems, setCertificateItems] = useState(candidateData || []);
    const [certificateIdToDelete, setCertificateIdToDelete] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const baseUrl = getApiBaseUrl();
    const { showAlert } = useContext(AlertContext);
    const { refetchCandidateCert } = useContext(CandidateContext)

    const handleAddCancelClick = () => {
        setShowAddForm(false);
    };

    const updateCertificateItems = (newCertificateItem) => {
        setCertificateItems((prevItems) => [...prevItems, newCertificateItem]);
        setShowAddForm(false);

    };
    useEffect(() => {
        if (candidateData) {
            setCertificateItems(candidateData);
        }
    }, [candidateData]);

    const handleDelete = async (AttachmentID) => {
        if (deleting) {
            return;
        }
        setDeleting(true);
        try {
            const response = await fetch(
                `${baseUrl}/api/Attachment/DeleteCandidateAttachment`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ AttachmentId: AttachmentID })

                }
            );
            const responseData = await response.json();
            if (responseData.success) {
                showAlert('Item deleted successfully.', true);
                refetchCandidateCert(); 
            }
            else {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
        } catch (error) {
            console.error('There was an error deleting the item:', error);
            showAlert('Oops, something went wrong. Please try again later.', false);
        }
        finally {
            setDeleting(false);
            setCertificateIdToDelete(null);
        }
    };

    useEffect(() => {
    }, [certificateItems]);
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <>
            {certificateItems.map((certificate, index) => {
                return (
                    <div className="certificate-item stealth-inn" key={certificate.AttachmentID}>
                        <div className="bnasdf_asldf certificate-item-read">
                            <div className="card-body d-flex justify-content-between align-items-center">
                                <div>
                                    <h5 className="d-block h5 font-semibold mb-1">{certificate.FileName} </h5>
                                {/*    <h3 className="text-sm text-muted">Created: <h3 className="text-sm text-muted">Created: {formatDate(certificate.DateTimeCreated)}</h3> </h3>*/}
                                    
                                </div>

                                <div className="text-end mx-n2 d-flex align-items-center">
                                    <a href={certificate.Uri} target="_blank" rel="noreferrer" className="download-but btn me-2-15"></a>
                                    <button className="btn me-2-15 delete-but" data-bs-target="#deletecertificateConfirm" onClick={() => setCertificateIdToDelete(certificate.AttachmentID)}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            {showAddForm && (
                <CertificateAddForm
                    candidateID={candidateID}
                    token={token}
                    onCancel={handleAddCancelClick}
                    updateCertificateItems={updateCertificateItems}
                />
            )}
            <div className="text-center skill-create-kksj">
                <button className="btn btn-primary profile-button-w200" onClick={() => setShowAddForm(true)}><i className="bi bi-plus"></i></button>
            </div>

            <Modal backdrop="true" show={certificateIdToDelete !== null} onHide={() => setCertificateIdToDelete(null)} centered>
                <Modal.Header>
                    <div className="icon icon-shape rounded-3 bg-soft-primary text-danger text-lg me-4">
                        <i className="bi bi-trash"></i>
                    </div>
                    <Modal.Title>Delete, Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleDelete(certificateIdToDelete)} disabled={deleting}>
                        {deleting ? 'Deleting...' : 'Delete Now'}
                    </Button>
                    <Button variant="neutral" onClick={() => setCertificateIdToDelete(null)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>

    );

}

export default CandidateCertificate;
