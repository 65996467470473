import React from 'react';
import { useIsAuthenticated } from "@azure/msal-react";

const CandidateBusProfilePicture = ({ candidateData, profilePic }) => {
    const isAuthenticated = useIsAuthenticated();
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    const profilepicuri = profilePic.Uri;
    return (
        <div className="profile-dash-asdlf">
            <div className="image-upload">
                <label htmlFor="file_upload_pp">
                    <span className="PlaceHolder-Sub-PP">
                        <span className="PlaceHolder-PP">
                            {profilepicuri ? (
                                <img id="ProPic" src={profilepicuri} alt="Selected Profile Preview" />
                            ) : profilepicuri ? (
                                <img id="ProPic" src={`${profilepicuri}?timestamp=${Date.now()}`} alt={`${candidateData.FirstName} profile picture`} />
                            ) : (
                                <>
                                    {candidateData.FirstName[0].toUpperCase()}
                                    {candidateData.LastName[0].toUpperCase()}
                                </>
                            )}
                        </span>
                    </span>
                </label>
            </div>
           
        </div>
    );
}

export default CandidateBusProfilePicture;
