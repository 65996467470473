import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { getApiBaseUrl } from "../utils/Config";
import { loginRequest, b2cPolicies } from "../utils/authConfig";
import { InteractionStatus } from "@azure/msal-browser";

const AuthCallback = () => {
    const { instance, inProgress } = useMsal();
    const navigate = useNavigate();
    const baseUrl = getApiBaseUrl();
    const [error, setError] = useState(null);

    const fetchWithTimeout = (url, options, timeout = 10000) => {
        return Promise.race([
            fetch(url, options),
            new Promise((_, reject) =>
                setTimeout(() => reject(new Error("API timeout")), timeout)
            ),
        ]);
    };

    useEffect(() => {
        if (inProgress !== InteractionStatus.None) return;

        const handleRedirect = async () => {
            try {
                const response = await instance.handleRedirectPromise();

                // Handle password reset redirect
                const passwordResetPolicy = b2cPolicies.names.forgotPassword;
                if (
                    response?.idTokenClaims?.tfp === passwordResetPolicy ||
                    response?.idTokenClaims?.acr === passwordResetPolicy
                ) {
                    return instance.loginRedirect({
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        redirectUri: `${window.location.origin}/auth/callback`,
                    });
                }

                // If no interaction response, fallback to accounts
                const currentAccounts = instance.getAllAccounts();
                if (currentAccounts.length === 0) {
                    return instance.loginRedirect(loginRequest);
                }

                const activeAccount = currentAccounts[0];

                const tokenResponse = await instance.acquireTokenSilent({
                    ...loginRequest,
                    account: activeAccount,
                });

                const b2cObjectId = activeAccount.localAccountId;

                const responseCheck = await fetchWithTimeout(
                    `${baseUrl}/api/Authentication/DoesCandidateExist?b2cObjectId=${b2cObjectId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${tokenResponse.accessToken}`,
                        },
                    },
                    10000
                );

                if (!responseCheck.ok) {
                    throw new Error(`API responded with ${responseCheck.status}`);
                }

                const data = await responseCheck.json();

                const returnTo = sessionStorage.getItem("postLoginRedirect") || "/candidate/profile";
                sessionStorage.removeItem("postLoginRedirect");

                if (data.success && data.result) {
                    navigate(returnTo);
                } else {
                    navigate("/candidate/additional-info");
                }

            } catch (err) {
                console.error("Auth callback error:", err);
                const errorMessage = err?.errorMessage || err?.message || "";

                if (errorMessage.includes("AADB2C90118")) {
                    return instance.loginRedirect({
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        redirectUri: `${window.location.origin}/auth/callback`,
                    });
                }

                if (errorMessage.includes("AADB2C90091")) {
                    return navigate("/");
                }

                setError("We're having trouble completing sign in. You will be logged out.");
                // Optional: setTimeout(() => instance.logoutRedirect(), 3000);
            }
        };

        handleRedirect();
    }, [inProgress, instance, navigate, baseUrl]);

    return (
        <div className="loadingStyle">
            {error ? <p>{error}</p> : <p>Loading...</p>}
        </div>
    );
};

export default AuthCallback;

