// Layout.js
import React, { useState, useEffect } from 'react';
import HomeHeader from '../pages/HomeHeader';
import HomeFooter from '../pages/HomeFooter';

function Layout({ children }) {
    return (
        <div>
            <HomeHeader />
            <main>
                {children}
            </main>
            <footer className="vstack gap-6 mt-auto">
                <HomeFooter />
            </footer>
        </div>
    );
}

export default Layout;
