import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { getApiBaseUrl } from '../utils/Config';

function JobSearch() {
    const [query, setQuery] = useState('');
    const [locations, setLocations] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const baseUrl = getApiBaseUrl();
    const [showMobileFilters, setShowMobileFilters] = useState(false);

    const toggleMobileFilters = () => {
        setShowMobileFilters(!showMobileFilters);
    };

    const closeMobileFilters = () => {
        setShowMobileFilters(false);
    };
    const [searchQuery, setSearchQuery] = useState({
        what: searchParams.get('what') || '',
        state: searchParams.get('state') || '',
        suburb: searchParams.get('suburb') || '',
        postcode: searchParams.get('postcode') || '',
        proximity: searchParams.get('proximity') || '0',
        sponsorship: searchParams.has('sponsorship') ? searchParams.get('sponsorship') === 'true' : null, // Null if not present
        categoryId: searchParams.get('categoryId') || null,
        salaryRange: searchParams.get('salaryRange') || null,
        pageNumber: searchParams.get('pageNumber') || '1',
        remote: searchParams.has('remote') ? searchParams.get('remote') === 'true' : null, // Null if not present
    });

    useEffect(() => {
        const newQuery = {
            what: searchParams.get('what') || '',
            state: searchParams.get('state') || '',
            suburb: searchParams.get('suburb') || '',
            postcode: searchParams.get('postcode') || '',
            proximity: searchParams.get('proximity') || '0',
            sponsorship: searchParams.has('sponsorship') ? searchParams.get('sponsorship') === 'true' : null,
            categoryId: searchParams.get('categoryId') || null,
            salaryRange: searchParams.get('salaryRange') || null,
            pageNumber: searchParams.get('pageNumber') || '1',
            remote: searchParams.has('remote') ? searchParams.get('remote') === 'true' : null,
        };
        setSearchQuery(newQuery);
    }, [searchParams]);

    const commonSearches = [
        'Software Developer',
        'Frontend Developer',
        'Backend Developer',
        'Full Stack Developer',
        'DevOps Engineer',
        'Cybersecurity Analyst',
        'Systems Administrator',
        'Data Scientist',
        'Cloud Engineer',
        'IT Support',
    ];
    const commonSearchCategoryMap = {
        'Software Developer': 18,           // Software Development and Engineering
        'Frontend Developer': 22,          // Web Development and Design
        'Backend Developer': 18,           // Software Development and Engineering
        'Full Stack Developer': 18,        // Software Development and Engineering
        'DevOps Engineer': 7,              // DevOps Engineering
        'Cybersecurity Analyst': 4,        // Cybersecurity and Information Security
        'Systems Administrator': 19,       // System Administration
        'Data Scientist': 6,               // Data Science and Analytics
        'Cloud Engineer': 3,               // Cloud Computing and Architecture
        'IT Support': 14,                  // IT Support and Helpdesk
    };

    const handleCommonSearch = (jobTitle) => {
        const matchedCategoryId = commonSearchCategoryMap[jobTitle] || null;

        setSearchQuery({
            ...searchQuery,
            what: jobTitle,
            categoryId: matchedCategoryId,
        });

        const queryParams = new URLSearchParams(searchParams);
        queryParams.set('what', jobTitle);
        if (matchedCategoryId) {
            queryParams.set('categoryId', matchedCategoryId);
        } else {
            queryParams.delete('categoryId');
        }

        navigate(`/jobs?${queryParams.toString()}`, { replace: true });
    };


    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [selectedSalaries, setSelectedSalaries] = useState([]);
    const [selectedEmployment, setSelectedEmployment] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const salaryOptions = [
        { label: "Under $30,000", value: 1 },
        { label: "$30,000 - $50,000", value: 2 },
        { label: "$50,000 - $70,000", value: 3 },
        { label: "$70,000 - $90,000", value: 4 },
        { label: "$90,000 - $110,000", value: 5 },
        { label: "$110,000 - $130,000", value: 6 },
        { label: "$130,000 - $150,000", value: 7 },
        { label: "$150,000 - $170,000", value: 8 },
        { label: "$170,000 - $190,000", value: 9 },
        { label: "$190,000 - $210,000", value: 10 },
        { label: "$210,000 - $230,000", value: 11 },
        { label: "$230,000 - $250,000", value: 12 },
        { label: "$250,000 and above", value: 13 },
        { label: "Negotiable", value: 14 },
        { label: "Competitive", value: 15 },
    ];

    const employmentOptions = [
        { label: 'Full-Time', value: 1 },
        { label: 'Part-Time', value: 2 },
        { label: 'Casual', value: 3 },
        { label: 'Contract', value: 4 },
        { label: 'Temporary', value: 5 },
        { label: 'Freelance', value: 6 },
        { label: 'Internship', value: 7 },
        { label: 'Consultancy', value: 8 },
        { label: 'Apprenticeship', value: 9 },
    ];

    const categoryOptions = [
        { label: 'Artificial Intelligence and Machine Learning', value: 1 },
        { label: 'Business Intelligence and Data Analysis', value: 2 },
        { label: 'Cloud Computing and Architecture', value: 3 },
        { label: 'Cybersecurity and Information Security', value: 4 },
        { label: 'Database Administration and Development', value: 5 },
        { label: 'Data Science and Analytics', value: 6 },
        { label: 'DevOps Engineering', value: 7 },
        { label: 'Executive Leadership (e.g., CIO, CTO)', value: 8 },
        { label: 'IT Consulting and Strategy', value: 9 },
        { label: 'IT Education and Training', value: 10 },
        { label: 'IT Management', value: 11 },
        { label: 'IT Project Management', value: 12 },
        { label: 'IT Research and Development', value: 13 },
        { label: 'IT Support and Helpdesk', value: 14 },
        { label: 'Mobile Application Development', value: 15 },
        { label: 'Network and Infrastructure', value: 16 },
        { label: 'Quality Assurance and Testing', value: 17 },
        { label: 'Software Development and Engineering', value: 18 },
        { label: 'System Administration', value: 19 },
        { label: 'Technical Writing and Documentation', value: 20 },
        { label: 'UI/UX Design', value: 21 },
        { label: 'Web Development and Design', value: 22 },
    ];

    const fetchLocations = async (searchTerm) => {
        if (!searchTerm) {
            setLocations([]);
            return;
        }

        try {
            const response = await axios.get(`${baseUrl}/api/LookUp/GetLocationSearchJob`, {
                params: {
                    postcode: searchTerm,
                    suburb: searchTerm,
                    state: searchTerm,
                },
            });
            setLocations(response.data.result || []);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (query.length >= 3) {
                fetchLocations(query);
            }
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [query]);

    const handleSelectLocation = (selectedLocation) => {
        setSearchQuery({
            ...searchQuery,
            state: selectedLocation.State,
            suburb: selectedLocation.Suburb,
            postcode: selectedLocation.Postcode,
        });
        setQuery(`${selectedLocation.Suburb}, ${selectedLocation.State} (${selectedLocation.Postcode})`);
        setShowDropdown(false);
    };

    const handleSalaryOptionChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedSalaries([...selectedSalaries, value]);
        } else {
            setSelectedSalaries(selectedSalaries.filter((salary) => salary !== value));
        }
    };

    const handleEmploymentOptionChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedEmployment([...selectedEmployment, value]);
        } else {
            setSelectedEmployment(selectedEmployment.filter((employment) => employment !== value));
        }
    };


    const handleInputChange = (event) => {
        setQuery(event.target.value);
        setShowDropdown(true);
    };
    const handleSelectCategory = (event) => {
        setSelectedCategory(event.target.value);
        setSearchQuery({ ...searchQuery, categoryId: event.target.value });
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        const queryParams = new URLSearchParams();

        if (searchQuery.what) queryParams.append('what', searchQuery.what);
        if (searchQuery.salaryRange) queryParams.append('salaryRange', searchQuery.salaryRange);
        if (selectedEmployment.length) queryParams.append('worktype', selectedEmployment.join(','));
        if (searchQuery.postcode) queryParams.append('postcode', searchQuery.postcode);
        if (searchQuery.state) queryParams.append('state', searchQuery.state);
        if (searchQuery.suburb) queryParams.append('suburb', searchQuery.suburb);
        if (searchQuery.proximity && searchQuery.proximity !== '0') queryParams.append('proximity', searchQuery.proximity);
        if (searchQuery.sponsorship !== null) queryParams.append('sponsorship', searchQuery.sponsorship);
        if (searchQuery.remote !== null) queryParams.append('remote', searchQuery.remote);
        if (selectedCategory) queryParams.append('categoryId', selectedCategory);

        navigate(`/jobs?${queryParams.toString()}`, { replace: true });
    };

    useEffect(() => {
        console.log('Updated searchParams:', searchParams.toString());
    }, [searchParams]);


    return (
        <div className="search-section-desk">
            <div className="search-section-inner-grb">
                <div className="container-fluid container-index-w234">
                    <div className="search-section-vt">
                        <h1 className="search-section-hd css-sc-hd">
                            <span className="css-sc-hd_21">Upgrade</span> your career.
                        </h1>
                        <div className="search-section-sb-outer">
                            {/*Desktop Search*/}
                            <form className="search-section-vt-form-al" onSubmit={handleSubmit}>
                                <div className="search-section-vt-form-int pasdk_slk">
                                    <div className="search-section-vt-form-what">
                                        <div className="input-group input-group-lg input-group-inline mb-1">
                                            <span className="input-group-text fontWeightS">What</span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Job Title or Keyword"
                                                name="what"
                                                value={searchQuery.what}
                                                onChange={(e) =>
                                                    setSearchQuery({ ...searchQuery, what: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="search-section-vt-form-where position-relative">
                                        <div className="input-group input-group-lg input-group-inline mb-1">
                                            <span className="input-group-text fontWeightS">Where</span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter suburb, state, or postcode"
                                                value={query}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        {showDropdown && locations.length > 0 && (
                                            <ul className="dropdown-menu show">
                                                {locations.map((location) => (
                                                    <li
                                                        key={location.LocationID}
                                                        className="dropdown-item"
                                                        onClick={() => handleSelectLocation(location)}
                                                    >
                                                        {`${location.Suburb}, ${location.State} (${location.Postcode})`}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="search-section-vt-form-button">
                                        <button type="submit" className="btn btn-primary btn-lg">
                                            <span className="ssri-subbut-inner-span ssri-subbut-inner-sub">
                                                <i className="bi bi-search"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div className="common-searches mb-3">
                                    <p className="common-search-title">Popular Searches:</p>
                                    <div className="common-search-buttons">
                                        {commonSearches.map((job) => (
                                            <button
                                                key={job}
                                                className="btn btn-stack-tal me-2 mb-2"
                                                onClick={() => handleCommonSearch(job)}
                                            >
                                                {job}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div className="filer_more_op">
                                    <div className="filter-container">
                                        <div className="filter-desk">
                                            <div className="row align-items-center">
                                                <div className="col-12">
                                                    {showMoreOptions && (
                                                        <div className="filer_sub_buttons hstack gap-3">
                                                            <div className="dropdown">
                                                                <button
                                                                    className="btn btn-secondary dropdown-toggle"
                                                                    type="button"
                                                                    id="employmentDropdown"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    Employment Type
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="employmentDropdown">
                                                                    {employmentOptions.map((option) => (
                                                                        <li key={option.value}>
                                                                            <label htmlFor={`employment-option-${option.value}`} className="dropdown-item">
                                                                                <input
                                                                                    className="form-check-input me-2"
                                                                                    type="checkbox"
                                                                                    value={option.value}
                                                                                    id={`employment-option-${option.value}`}
                                                                                    onChange={handleEmploymentOptionChange}
                                                                                    checked={selectedEmployment.includes(option.value.toString())} // Ensure consistency with string values
                                                                                />
                                                                                {option.label}
                                                                            </label>
                                                                        </li>
                                                                    ))}
                                                                </ul>

                                                            </div>

                                                            <div className="dropdown">
                                                                <button
                                                                    className="btn btn-secondary dropdown-toggle"
                                                                    type="button"
                                                                    id="salaryDropdown"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    Salary
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="salaryDropdown">
                                                                    {salaryOptions.map((option) => (
                                                                        <li key={option.value}>
                                                                            <label htmlFor={`salary-option-${option.value}`} className="dropdown-item">
                                                                                <input
                                                                                    className="form-check-input me-2"
                                                                                    type="radio"
                                                                                    name="salaryRange"
                                                                                    value={option.value}
                                                                                    id={`salary-option-${option.value}`}
                                                                                    onChange={(e) =>
                                                                                        setSearchQuery({
                                                                                            ...searchQuery,
                                                                                            salaryRange: e.target.value,
                                                                                        })
                                                                                    }
                                                                                    checked={searchQuery.salaryRange === option.value.toString()} // Ensure consistency with string values
                                                                                />
                                                                                {option.label}
                                                                            </label>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>

                                                            <div className="dropdown">
                                                                <button
                                                                    className={`btn btn-secondary dropdown-toggle ${selectedCategory ? 'active' : ''
                                                                        }`}
                                                                    type="button"
                                                                    id="categoryDropdown"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    {selectedCategory
                                                                        ? categoryOptions.find((cat) => cat.value.toString() === selectedCategory)?.label
                                                                        : 'Category'}
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="categoryDropdown">
                                                                    <li>
                                                                        <button
                                                                            className="dropdown-item"
                                                                            onClick={() => {
                                                                                setSelectedCategory('');
                                                                                setSearchQuery({ ...searchQuery, categoryId: null });
                                                                            }}
                                                                        >
                                                                            Clear Category
                                                                        </button>
                                                                    </li>
                                                                    {categoryOptions.map((category) => (
                                                                        <li key={category.value}>
                                                                            <button
                                                                                className="dropdown-item"
                                                                                onClick={() => {
                                                                                    setSelectedCategory(category.value.toString());
                                                                                    setSearchQuery({ ...searchQuery, categoryId: category.value });
                                                                                }}
                                                                            >
                                                                                {category.label}
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>

                                                            <button
                                                                type="button"
                                                                className={`btn btn-secondary ${searchQuery.sponsorship ? 'active' : ''}`}
                                                                onClick={() =>
                                                                    setSearchQuery({
                                                                        ...searchQuery,
                                                                        sponsorship: searchQuery.sponsorship === true ? null : true, // Toggle between `null` and `true`
                                                                    })
                                                                }
                                                            >
                                                                Sponsorship {searchQuery.sponsorship && <i className="bi bi-check-lg ms-2"></i>}
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className={`btn btn-secondary ${searchQuery.remote ? 'active' : ''}`}
                                                                onClick={() =>
                                                                    setSearchQuery({
                                                                        ...searchQuery,
                                                                        remote: searchQuery.remote === true ? null : true, // Toggle between `null` and `true`
                                                                    })
                                                                }
                                                            >
                                                                Remote {searchQuery.remote && <i className="bi bi-check-lg ms-2"></i>}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-sm-auto col-12 mt-4 mt-sm-0">
                                                    {!showMoreOptions && (
                                                        <div className="hstack gap-2 justify-content-sm-end filer_sub_toggle">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-light border-base"
                                                                onClick={() => setShowMoreOptions(!showMoreOptions)}
                                                            >
                                                                <span>Show more options </span>
                                                                <span className="pe-2">
                                                                    <i className="bi bi-sliders rotate-icon"></i>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Mobile Filter Button */}
                                        <div className="mobile-filter-fpa">
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-light border-base mobile-filter-button"
                                                onClick={toggleMobileFilters}
                                            >
                                                <i className="bi bi-sliders"></i>
                                            </button>

                                            {/*<input type="checkbox" id="mobile-filter-toggle" className="mobile-filter-checkbox" />*/}

                                            {/*<label htmlFor="mobile-filter-toggle" className="mobile-filter-button">*/}
                                            {/*    Filters <i className="bi bi-funnel"></i>*/}
                                            {/*</label>*/}
                                        </div>

                                        {/* Mobile Full-Screen Filter Modal */}
                                        {showMobileFilters && (
                                            <div className="mobile-filter-overlay">
                                                <div className="mobile-filter-content">
                                                    <button className="filter-close-button" onClick={closeMobileFilters}>
                                                        <i className="bi bi-x-lg"></i>
                                                    </button>

                                                    <h2>Filter Jobs</h2>

                                                    <div className="filter-groups">
                                                        {/* Employment Type */}
                                                        <div className="filter-group">
                                                            <h4>Employment Type</h4>
                                                            <div className="filter-options">
                                                                {employmentOptions.map((option) => (
                                                                    <label key={option.value} className="filter-box">
                                                                        <input
                                                                            type="checkbox"
                                                                            value={option.value}
                                                                            onChange={handleEmploymentOptionChange}
                                                                            checked={selectedEmployment.includes(option.value.toString())}
                                                                        />
                                                                        {option.label}
                                                                    </label>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        {/* Salary Range */}
                                                        <div className="filter-group">
                                                            <h4>Salary</h4>
                                                            <div className="filter-options">
                                                                {salaryOptions.map((option) => (
                                                                    <label key={option.value} className="filter-box">
                                                                        <input
                                                                            type="radio"
                                                                            name="salaryRange"
                                                                            value={option.value}
                                                                            onChange={(e) =>
                                                                                setSearchQuery({
                                                                                    ...searchQuery,
                                                                                    salaryRange: e.target.value,
                                                                                })
                                                                            }
                                                                            checked={searchQuery.salaryRange === option.value.toString()}
                                                                        />
                                                                        {option.label}
                                                                    </label>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        {/* Additional Filters */}
                                                        <div className="filter-group">
                                                            <h4>Additional Filters</h4>
                                                            <div className="filter-options">
                                                                <label className={`filter-box ${searchQuery.sponsorship ? 'active' : ''}`}
                                                                    onClick={() => setSearchQuery({ ...searchQuery, sponsorship: searchQuery.sponsorship ? null : true })}
                                                                >
                                                                    Sponsorship {searchQuery.sponsorship && <i className="bi bi-check-lg"></i>}
                                                                </label>

                                                                <label className={`filter-box ${searchQuery.remote ? 'active' : ''}`}
                                                                    onClick={() => setSearchQuery({ ...searchQuery, remote: searchQuery.remote ? null : true })}
                                                                >
                                                                    Remote {searchQuery.remote && <i className="bi bi-check-lg"></i>}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <button className="apply-filters" onClick={closeMobileFilters}>Apply Filters</button>
                                                </div>
                                            </div>
                                        )}



                                    </div>
                                </div>


                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JobSearch;
