import React, { useContext,useEffect } from 'react';
import { CandidateContext } from '../../Context/CandidateContext';
import { useIsAuthenticated } from "@azure/msal-react";
//Import Pages
import CandidateBusPersonalInfo from '../../pages/Candidate/CandidateBusPersonalInfo';
import CandidateBusPersonalDetails from '../../pages/Candidate/CandidateBusPersonalDetails';
import CandidateBusProfilePicture from '../../pages/Candidate/CandidateBusProfilePicture';
import CandidateBusSummary from '../../pages/Candidate/CandidateBusSummary';
import CandidateBusSocials from '../../pages/Candidate/CandidateBusSocials';
import CandidateBusExperience from '../../pages/Candidate/CandidateBusExperience';
import CandidateBusEducation from '../../pages/Candidate/CandidateBusEducation';
import CandidateBusSkill from '../../pages/Candidate/CandidateBusSkills';
import CandidateBusResume from '../../pages/Candidate/CandidateBusResume';
import CandidateBusCertificate from '../../pages/Candidate/CandidateBusCertificate';
import CandidateBusCover from '../../pages/Candidate/CandidateBusCover';
import image from '../../images/profile-back-im.png';
function BusinessView() {
    useEffect(() => {
        document.title = "How Businesses See You | Stacktalent.com.au";
    }, []);
    const isAuthenticated = useIsAuthenticated();


    const {
        apiData,
        apiDataDetails,
        apiDataPreferences,
        apiDataSummary,
        apiDataSocial,
        apiDataExperience,
        apiDataEducation,
        apiDataSkill,
        apiCandidateID,
        profilePicUrl,
        apiCandidateResume,
        apiCandidateCertificate,
        apiCandidateCover,
    } = useContext(CandidateContext);
    const isProfileComplete = apiDataSummary && apiDataExperience && apiDataExperience.length > 0 && apiDataEducation && apiDataEducation.length > 0;

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    return (
            <div className="flex-lg-1 h-screen overflow-y-lg-auto profile-lalk_lo profile-lalk_bus">
                <header className="bg-surface-secondary">
                    <div className="dashboad-outer-cont-dash">
                        <div className="container-fluid max-w-screen-xl mt-7">
                            <div className="row g-6">
                                <div className="col-xl-8 vstack gap-6">
                                    <div className="h-full">
                                        <div className="persInfoCard">
                                            <div className="row g-0">
                                                <div className="col-auto">
                                                    {profilePicUrl && <CandidateBusProfilePicture candidateData={apiData} candidateDetails={apiDataDetails} profilePic={profilePicUrl} />}
                                                </div>
                                                <div className="col ps-4 pt-4">
                                                    <CandidateBusPersonalInfo candidateData={apiData} candidateDetails={apiDataDetails} candidateDataPref={apiDataPreferences} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                                {apiData && apiDataDetails && (
                                    <div className="col-xl-4 vstack gap-6">
                                        <div className="h-full">
                                            <div className="persInfoCard">
                                                <div className="row g-0">
                                                    <div className="col ps-4 pt-4">
                                                        <CandidateBusPersonalDetails candidateDatadetails={apiData} candidateDetailsmore={apiDataDetails} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {apiDataSocial && (
                                    <div className="d-flex gap-2 flex-wrap mt-3 mb-3 d-none d-sm-block soc_asdl-owpc">
                                        <div className="ps-4">
                                            <CandidateBusSocials candidateData={apiDataSocial} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </header>

                <main className="py-6 bg-surface-secondary">
                    <div className="container-fluid max-w-screen-xl">
                        <div className="row g-6">
                            <div className="col-xl-12">
                                {!isProfileComplete && (
                                    <div className="text-center mt-4">
                                        <h2 className="mb-2">You have not completed your profile</h2>
                                        <p>To stand out among other candidates, make sure to:</p>
                                        <ul className="text-start mx-auto" style={{ maxWidth: '300px' }}>
                                            <li>Complete your business summary</li>
                                            <li>Add your work experience and education history</li>
                                            <li>Showcase your skills</li>
                                            <li>Upload your resume for employers to see</li>
                                        </ul>
                                        <p>Completing your profile gives employers a clearer picture of your employment history and increases your chances of being noticed!</p>
                                    </div>
                                )}
                                {isProfileComplete && (
                                    <>
                                        {apiDataSummary && (
                                            <div className="vstack gap-6 summary-create-k_idj">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="mb-3">Summary</h5>
                                                    </div>
                                                    <div className="card-body-askop">
                                                        <div className="hausld ldfin hausld-Summary">
                                                            <CandidateBusSummary candidateData={apiDataSummary} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {apiDataExperience && apiDataExperience.length > 0 && (
                                            <div className="vstack gap-6 education-create-k_idj mt-7">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="mb-3">Work Experience</h5>
                                                    </div>
                                                    <div className="card-body-askop">
                                                        <div className="hausld ldfin hausld-Exp">
                                                            <CandidateBusExperience candidateData={apiDataExperience} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {apiDataEducation && apiDataEducation.length > 0 && (
                                            <div className="vstack gap-6 education-create-k_idj mt-7">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="mb-3">Education History</h5>
                                                    </div>
                                                    <div className="card-body-askop">
                                                        <div className="hausld ldfin hausld-Edu">
                                                            <CandidateBusEducation candidateData={apiDataEducation} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {apiDataSkill && apiDataSkill.length > 0 && (
                                            <div className="vstack gap-6 skills-create-k_idj mt-7">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="mb-3">Skills</h5>
                                                    </div>
                                                    <div className="card-body-askop">
                                                        <div className="hausld ldfin hausld-Skill">
                                                            <div className="skill-item">
                                                                <div className="bnasdf_asldf skill-item-read">
                                                                    <div className="card-body car-dlj-ds d-flex align-items-center">
                                                                        <CandidateBusSkill candidateData={apiDataSkill} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {apiCandidateCertificate && apiCandidateCertificate.length > 0 && (
                                            <div className="vstack gap-6 certificate-create-k_idj mt-7">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="mb-3">Certificates</h5>
                                                    </div>
                                                    <div className="card-body-askop">
                                                        <div className="hausld ldfin hausld-Certificate">
                                                            <CandidateBusCertificate candidateData={apiCandidateCertificate} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    )}

                                    {apiCandidateResume && apiCandidateResume.length > 0 && (
                                        <div className="vstack gap-6 certificate-create-k_idj mt-7">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="mb-3">Resume</h5>
                                                </div>
                                                <div className="card-body-askop">
                                                    <div className="hausld ldfin hausld-Certificate">
                                                        <CandidateBusResume candidateData={apiCandidateResume} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
    );
}

export default BusinessView;
