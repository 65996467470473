import React from "react";
import { Link } from "react-router-dom"; 
import NotFoundImage from "../../src/images/page_not_found.svg"; 

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen text-center p-6">
            <img src={NotFoundImage} alt="Page not found" className="w-96 max-w-full mb-6" />
            <h1 className="text-4xl font-bold text-purple-700 mb-4">Oops! This page doesn't exist.</h1>
            <p className="text-gray-600 mb-6">
                Looks like you've wandered into the void. The page you're looking for might have been moved or never existed.
            </p>
            <Link
                to="/"
                className="btn btn-primary"
            >
                Take Me Home
            </Link>
        </div>
    );
};

export default NotFound;
