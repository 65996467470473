import React, { useState, useEffect,useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import EducationForm from './EducationForm';
import EducationAddForm from '../../pages/Candidate/EducationAdd';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useIsAuthenticated } from "@azure/msal-react";
import { CandidateContext } from '../../Context/CandidateContext';


const CandidateEducation = ({ candidateID, candidateData }) => {
    const isAuthenticated = useIsAuthenticated();
    const { token, idTokenClaims } = useContext(TokenContext);
    const [editingIndex, setEditingIndex] = useState(null);
    const [showAddForm, setShowAddForm] = useState(false);
    const [educationItems, setEducationItems] = useState(candidateData || []);
    const [educationIdToDelete, setEducationIdToDelete] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const baseUrl = getApiBaseUrl();
    const { showAlert } = useContext(AlertContext);
    const { refetchCandidateDetails } = useContext(CandidateContext)

    const handleEditClick = (index) => {
        setEditingIndex(index);
    };

    const handleCancelClick = () => {
        setEditingIndex(null);
    };
    const handleAddCancelClick = () => {
        setShowAddForm(false);
    };

    const updateEducationItems = (newEducationItem) => {
        setEducationItems((prevItems) => [...prevItems, newEducationItem]);
        setShowAddForm(false);

    };
    useEffect(() => {
        if (candidateData) {
            setEducationItems(candidateData);
        }
    }, [candidateData]);

    const handleUpdate = (updatedItem) => {

        setEducationItems(prevItems => prevItems.map(item => item.EducationID === updatedItem.EducationID ? updatedItem : item));
        setEditingIndex(null);
    };
    const handleDelete = async (EducationId) => {
        if (deleting) {
            return;
        }
        setDeleting(true);

        try {
            const response = await fetch(
                `${baseUrl}/api/Education/Delete?EducationId=${EducationId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseData = await response.json();
            if (responseData.success) {
                //setEducationItems(prevItems => prevItems.filter(item => item.EducationID !== EducationId));
                showAlert('Item deleted successfully.', true);
                refetchCandidateDetails(); 
            }
            else {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
        } catch (error) {
            console.error('There was an error deleting the item:', error);
            showAlert('Oops, something went wrong. Please try again later.', false);
        }
        finally {
            setDeleting(false);
            setEducationIdToDelete(null);
        }
    };

    useEffect(() => {
    }, [educationItems]);
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <>
            {educationItems.map((education, index) => {
                return (
                    <div className="education-item stealth-inn" key={education.EducationID}>
                        {editingIndex === index ? (
                            <EducationForm
                                candidateID={candidateID}
                                candidateData={education}
                                token={token}
                                onCancel={handleCancelClick}
                                handleUpdate={handleUpdate}
                            />
                        ) : (
                            <div className="bnasdf_asldf education-item-read">
                                <div className="card-body d-flex justify-content-between align-items-center">
                                        <div>
                                            <h5 className="d-block h5 font-semibold mb-1 text-wrap">{education.Qualification}</h5>
                                            <h3 className="d-block text-sm text-muted text-wrap">
                                                {education.School}  {new Date(education.StartDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' })} to {education.EndDate ? new Date(education.EndDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' }) : "Current"}
                                            </h3>
                                        </div>

                                    <div className="text-end mx-n2 d-flex align-items-center">
                                        <button type="button" className="btn me-2-15 edit-education edit-but" onClick={() => handleEditClick(index)}></button>
                                        <button className="btn me-2-15 delete-but" data-bs-target="#deleteeduConfirm" onClick={() => setEducationIdToDelete(education.EducationID)}></button>
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                );
            })}
            {showAddForm && (
                <EducationAddForm
                    candidateID={candidateID}
                    token={token}
                    onCancel={handleAddCancelClick}
                    updateEducationItems={updateEducationItems}
                />
            )}
            <div className="text-center education-create-kksj">
                <button className="btn btn-primary profile-button-w200" onClick={() => setShowAddForm(true)}><i className="bi bi-plus"></i></button>
            </div>


            <Modal show={educationIdToDelete !== null} onHide={() => setEducationIdToDelete(null)} centered>
                <Modal.Header>
                    <div className="icon icon-shape rounded-3 bg-soft-primary text-danger text-lg me-4">
                        <i className="bi bi-trash"></i>
                    </div>
                    <Modal.Title>Delete, Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleDelete(educationIdToDelete)} disabled={deleting}>
                        {deleting ? 'Deleting...' : 'Delete Now'}
                    </Button>
                    <Button variant="neutral" onClick={() => setEducationIdToDelete(null)}>Close</Button>
                </Modal.Footer>
            </Modal>

        </>

    );

}

export default CandidateEducation;
