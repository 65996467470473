import React, { useState, useEffect } from 'react';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useIsAuthenticated } from "@azure/msal-react";

const CandidateBusPersonalSummary = ({ candidateData }) => {
    const isAuthenticated = useIsAuthenticated();
    const [formData, setFormData] = useState({
        Value: "",
    });
 
    useEffect(() => {
        if (candidateData) {
            setFormData({
                Value: candidateData.Value,
            });
        }
    }, [candidateData]);
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
  
    if (!candidateData) {
        return <SkeletonLoader />;
    }
    return (
        <div className="summary-item">
            <div className="bnasdf_asldf summary-item-read">
                <div className="card-body car-dlj-ds d-flex">
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: formData.Value }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CandidateBusPersonalSummary;
