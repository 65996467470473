import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";
import { getApiBaseUrl } from '../../utils/Config';
import logoImage from '../../images/stacktalent-339X103.png';
import { AlertContext } from '../../Context/AlertContext';
import Alert from '../../utils/GenAlert';
import { TokenContext } from '../../Context/TokenContext';
import { useCandidate } from '../../Context/CandidateContext';
import { useMsal } from "@azure/msal-react";

const InfoWizard = () => {
    const isAuthenticated = useIsAuthenticated();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const { showAlert } = useContext(AlertContext);
    const { instance } = useMsal();
    const user = instance.getActiveAccount(); 
    const { oid, token } = useContext(TokenContext);
    const { refetchCandidateDetails, success, fetching } = useCandidate();
    useEffect(() => {
        if (isSubmitting && !fetching && success) {
            setIsSubmitting(false);
            navigate('/candidate/profile');
        } else {
            console.log("Waiting for fetching and success");
        }
    }, [isSubmitting, fetching, success, navigate]);

   
    const handleCandidateSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const candidateData = {
            FirstName: firstName,
            LastName: lastName,
            Email: user.email,
            B2CObjectId: oid,
        };

        try {
            const baseUrl = getApiBaseUrl();
            const response = await fetch(`${baseUrl}/api/Candidate/Register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(candidateData)
            });
            const data = await response.json();

            console.log("Signup create data", data);

            if (data.success) {
                await refetchCandidateDetails(); // Trigger the refetch
            } else {
                throw new Error(data.message || "Failed to register candidate.");
            }
        } catch (error) {
            console.error('Error submitting details:', error);
            showAlert("Oops, something went wrong. Please try again later.", false);
            setIsSubmitting(false);
        }
    };
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <div>
            <AlertContext.Consumer>
                {({ alert }) => (
                    <>
                        {alert.visible && <Alert message={alert.message} success={alert.success} />}
                    </>
                )}
            </AlertContext.Consumer>
            <div className="px-5 py-5 p-lg-0 bg-surface-secondary d-flex flex-column justify-content-center">
                <div className="d-flex justify-content-center">
                    <div className="col-12 col-md-9 col-lg-6 min-h-lg-screen d-flex flex-column justify-content-center py-lg-16 px-lg-20 position-relative">
                        <div className="row">
                            <div className="col-lg-10 col-md-10 col-xl-10 mx-auto">
                                <div className="text-center mb-12">
                                    <a className="d-inline-block" href="#">
                                        <img src={logoImage} className="h-12" alt="Stacktalent white logo"></img>
                                    </a>
                                    <h1 className="ls-tight font-bolder mt-6">
                                        Almost Done
                                    </h1>
                                    <p className="mt-2">We need some additional information from you</p>
                                </div>
                                <div className="info-wizard">
                                    <form onSubmit={handleCandidateSubmit}>
                                        <h2>Candidate Information</h2>
                                        <div className="mb-5">
                                            <label className="form-label" htmlFor="firstName">First Name:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label" htmlFor="lastName">Last Name:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary w-full" disabled={isSubmitting}>
                                            {isSubmitting ? 'Submitting...' : 'Finish'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoWizard;
