// src/components/AuthProvider.js
import React, { useEffect, useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../utils/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const AuthProvider = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const initializeMsal = async () => {
            try {
                await msalInstance.initialize();
                setIsInitialized(true);
            } catch (err) {
                console.error("MSAL failed to initialize:", err);
            }
        };

        initializeMsal();
    }, []);

    if (!isInitialized) {
        return <div className="loadingStyle">Loading authentication...</div>;
    }

    return (
        <MsalProvider instance={msalInstance}>
            {children}
        </MsalProvider>
    );
};

export default AuthProvider;
