import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getApiBaseUrl } from '../utils/Config';
import { Helmet } from "react-helmet";
function Job() {
    const { jobId } = useParams();
    const [job, setJob] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const baseUrl = getApiBaseUrl();


    const handleApplyClick = () => {
        navigate(`/jobapply/${job.JobPostID}`);
    };
    useEffect(() => {
        const fetchJob = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const response = await fetch(`${baseUrl}/api/JobPostPublic/Get?jobPostId=${encodeURIComponent(jobId)}`);
                if (!response.ok) throw new Error('Error fetching job');
                const data = await response.json();
                setJob(data.result);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchJob();
    }, [jobId]);

    if (isLoading) return <p className="loadingStyle">Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!job) return <p>No job data found</p>;


    function getSalaryRangeText(salaryRangeValue) {
        const salaryRanges = {
            1: "Under $30,000",
            2: "$30,000 - $50,000",
            3: "$50,000 - $70,000",
            4: "$70,000 - $90,000",
            5: "$90,000 - $110,000",
            6: "$110,000 - $130,000",
            7: "$130,000 - $150,000",
            8: "$150,000 - $170,000",
            9: "$170,000 - $190,000",
            10: "$190,000 - $210,000",
            11: "$210,000 - $230,000",
            12: "$230,000 - $250,000",
            13: "$250,000 and above",
            14: "Negotiable",
            15: "Competitive",
        };

        return salaryRanges[salaryRangeValue] || "Not specified";
    }

    function getWorkTypeText(workTypeValue) {
        const workTypes = {
            1: "Full-Time",
            2: "Part-Time",
            3: "Casual",
            4: "Contract",
            5: "Temporary",
            6: "Freelance",
            7: "Internship",
            8: "Consultancy",
            9: "Apprenticeship",
        };

        return workTypes[workTypeValue] || "Not specified";
    }

    const calculateTimeElapsed = (startDateString) => {
        if (!startDateString) {
            return "Invalid date";
        }

        // Remove fractional seconds before parsing
        const cleanedStartDateString = startDateString.split('.')[0] + "Z";
        const startDateUTC = new Date(cleanedStartDateString);
        if (isNaN(startDateUTC.getTime())) {
            return "Invalid date";
        }

        // Get user's timezone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // Convert UTC date to local time
        const localStartDate = new Date(startDateUTC.toLocaleString("en-US", { timeZone: userTimeZone }));
        if (isNaN(localStartDate.getTime())) {
            return "Invalid date";
        }

        // Calculate difference
        const localNow = new Date();
        const timeDifference = localNow.getTime() - localStartDate.getTime();
        if (timeDifference < 0) return "Just now";

        const minutes = Math.floor(timeDifference / 60000);
        const hours = Math.floor(timeDifference / 3600000);
        const days = Math.floor(timeDifference / (3600000 * 24));

        if (minutes < 60) return `${minutes} min ago`;
        else if (hours < 24) return `${hours} hours ago`;
        return `${days} days ago`;
    };

    const isValidLocation = (location) => {
        const validLocations = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];
        return validLocations.includes(location) ? location : null;
    };

    return (
        <>
            <Helmet>
                <title>{job.Title} at {job.BusinessName} | {job.Location || 'Australia'} | Stacktalent.com.au</title>
                <meta name="description" content={`Apply for ${job.Title} at ${job.BusinessName}. Location: ${job.Location}. Find more job opportunities on Stacktalent.com.au`} />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "JobPosting",
                        "title": job.Title,
                        "description": job.Description.replace(/<\/?[^>]+(>|$)/g, ""), // Remove HTML for SEO
                        "datePosted": new Date(job.StartDate).toISOString(),
                        "hiringOrganization": {
                            "@type": "Organization",
                            "name": job.BusinessName
                        },
                        "jobLocation": {
                            "@type": "Place",
                            "address": {
                                "addressLocality": job.Location,
                                "addressCountry": "AU"
                            }
                        },
                        "employmentType": getWorkTypeText(job.WorkTypeID),
                        "baseSalary": job.SalaryRange > 0 && !job.IsSalaryHidden ? {
                            "@type": "MonetaryAmount",
                            "currency": "AUD",
                            "value": {
                                "@type": "QuantitativeValue",
                                "value": job.SalaryRange,
                                "unitText": "YEAR"
                            }
                        } : undefined
                    })}
                </script>
            </Helmet>
            <div className="container-xxl mb-7">
                <div className="container">
                    <article className="css-dlxf34 mt-4">
                        <div className="vstack gap-6">
                            <div className="job-card">
                                <div className="job-card-body">
                                    <div className="job-card-slw">
                                        <div className="job_post_header">
                                            <div className="d-flex align-items-center advert-pa-ou-iuj">
                                                <h1 className="me-auto">
                                                    {job.Title}
                                                </h1>
                                                <p>{calculateTimeElapsed(job.StartDate)}</p>
                                            </div>
                                            <h3 className="mb-3"> {job.BusinessName} {isValidLocation(job.Location) ? `| ${job.Location}` : ''}</h3>
                                        </div>
                                        <div className="job_post_sub_header mb-5">
                                            {job.SalaryRange > 0 && !job.IsSalaryHidden && (
                                                <span className="badge bg-cust-tag text-cust-d-pur me-2">
                                                    <i className="bi bi-coin"></i> {getSalaryRangeText(job.SalaryRange)}
                                                </span>
                                            )}
                                            <span className="badge bg-cust-tag text-cust-d-pur me-2">
                                                <i className="bi bi-briefcase"></i> {getWorkTypeText(job.WorkTypeID)}
                                            </span>
                                            {job.IsSponsorship && (
                                                <span className="badge bg-cust-tag text-cust-d-pur me-2">
                                                    <i className="bi bi-airplane"></i> Open to Sponsorship
                                                </span>
                                            )}
                                            {job.WorkModelTypeID === 1 && (
                                                <span className="badge bg-cust-tag text-cust-d-pur me-2">
                                                    <i className="bi bi-house"></i> Remote Possible
                                                </span>
                                            )}
                                        </div>
                                        <div className="job_post_quick_apply mb-5">
                                            <button
                                                className="btn btn-primary profile-button-w200"
                                                onClick={handleApplyClick}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                    <div className="job_post_body_descr">
                                        <div className="text-sm lh-relaxed mb-4" dangerouslySetInnerHTML={{ __html: job.Description }} />
                                    </div>

                                    {job.SelectedQuestions?.length > 0 && (
                                        <div className="job_post_questions">
                                            <h5>Employer Questions</h5>
                                            <ul>
                                                {job.SelectedQuestions.map((question) => (
                                                    <li key={question.jobPostQuestionID}>
                                                        {question.questionText}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}


                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </>
    );
}

export default Job;
