import React, { useState, useContext, useEffect } from 'react';
import { CandidateContext } from "../../Context/CandidateContext";
import { useIsAuthenticated } from "@azure/msal-react";
import SkeletonLoader from "../../utils/SkeletonLoader";
import CandidatePersonalInfo from "../../pages/Candidate/CandidatePersonalInfo";
import CandidateProfilePicture from "../../pages/Candidate/CandidateProfilePicture";
import CandidatePersonalDetails from "../../pages/Candidate/CandidatePersonalDetails";
import CandidatePreferences from "../../pages/Candidate/CandidatePreferences";
import CandidateSummary from "../../pages/Candidate/CandidateSummary";
import CandidateSocials from "../../pages/Candidate/CandidateSocials";
import CandidateExperience from "../../pages/Candidate/CandidateExperience";
import CandidateEducation from "../../pages/Candidate/CandidateEducation";
import CandidateSkill from "../../pages/Candidate/CandidateSkills";
import CandidateResume from "../../pages/Candidate/CandidateResume";
import CandidateCertificate from "../../pages/Candidate/CandidateCertificate";
import PersonalProfile from "./PersonalProfile";
import { getApiBaseUrl } from '../../utils/Config';
import { Modal, Button, Form } from 'react-bootstrap';
import { TokenContext } from '../../Context/TokenContext';
import { useMsal } from "@azure/msal-react";

import {
    User, Settings2, Briefcase, BookOpen, Star, Award, FileText, Share2
} from "lucide-react";
import { displayName } from "react-quill";

function Profile() {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const user = instance.getActiveAccount(); 

    const { token } = useContext(TokenContext);

    const {
        apiData,
        apiDataDetails,
        apiDataPreferences,
        apiDataSummary,
        apiDataSocial,
        apiDataExperience,
        apiDataEducation,
        apiDataSkill,
        apiCandidateID,
        profilePicUrl,
        apiCandidateResume,
        apiCandidateCertificate, isLoading, isCertLoading, isResumeLoading
    } = useContext(CandidateContext);
    const [showProfilePic, setShowProfilePic] = useState(true);
    const baseUrl = getApiBaseUrl();

    const [showModal, setShowModal] = useState(false);
    const [topic, setTopic] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const source = "Candidate";
    useEffect(() => {
        if (user?.email) {
            setEmail(user.email);
        }
    }, [user]);

    async function hashEmail(email) {
        const secretKey = "Uxu!57RW6re2kDSCbkM!pCVYv43BmNWAt*NcBHh8SfDXUWKCYHqn8~vP*wDsWe7VWgHQ4~AXUd5fBuaZ";
        const reversedEmail = email.split("").reverse().join("");

        const encoder = new TextEncoder();
        const keyData = encoder.encode(secretKey);
        const emailData = encoder.encode(reversedEmail);

        const key = await crypto.subtle.importKey(
            "raw", keyData, { name: "HMAC", hash: "SHA-256" }, false, ["sign"]
        );

        const signature = await crypto.subtle.sign("HMAC", key, emailData);

        return Array.from(new Uint8Array(signature))
            .map(byte => byte.toString(16).padStart(2, "0"))
            .join("");
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        setSuccess("");

        if (message.trim().length === 0) {
            setError("Message cannot be empty.");
            setLoading(false);
            return;
        }

        try {
            const hashedEmail = await hashEmail(email);
            const payload = {
                topic,
                email,
                message,
                source,
                key: hashedEmail
            };
            const response = await fetch(
                `${baseUrl}/api/Communications/SendSupportQuery`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            );

            const responseData = await response.json();

            if (response.ok && responseData.success) {
                setSuccess("Your request has been sent. Our support team will get back to you soon.");
                setMessage("");
                setTimeout(() => {
                    setSuccess("");
                    setShowModal(false);
                }, 3000);
            } else {
                throw new Error("Failed to send support request.");
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        document.title = "Edit Candidate Profile | TalentUp";
    }, []);

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <>
            <div className="flex-lg-1 h-screen overflow-y-lg-auto profile-lalk_lo">
                <div className="container-fluid mt-5">
                    <div className="col-auto">
                        <div className="hstack gap-2 justify-content-end">
                            <Button variant="link" className="text-sm text-muted text-primary-hover font-semibold me-2 d-md-block"
                                onClick={() => setShowModal(true)}
                            >
                                <i className="bi bi-question-circle-fill"></i>
                                <span className="d-sm-inline ms-2">Need help?</span>
                            </Button>
                        </div>
                    </div>
                    <div className="vstack gap-6 pref-create-k_idj mb-3">
                        <div className="card">
                            <div className="card-body persInfoCard">
                                <div className="row g-0">
                                    <div className="col-auto">
                                        {showProfilePic && <CandidateProfilePicture candidateData={apiData} candidateID={apiCandidateID} profilePic={profilePicUrl} />}
                                    </div>
                                    <div className="col">
                                        {isLoading ? <SkeletonLoader /> : <CandidatePersonalInfo candidateData={apiData} profilePicControl={{ hide: () => setShowProfilePic(false), show: () => setShowProfilePic(true) }} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion" id="profileAccordion">

                        {/* Other sections */}
                        {[
                            { id: "PersonalDetails", displayName: "Personal Details", component: CandidatePersonalDetails, data: apiDataDetails, icon: <User size={18} /> },
                            { id: "Preferences", displayName: "Preferences", component: CandidatePreferences, data: apiDataPreferences, icon: <Settings2 size={18} /> },
                            { id: "Summary", displayName: "Summary", component: CandidateSummary, data: apiDataSummary, icon: <User size={18} /> },
                            { id: "Experience", displayName: "Work Experience", component: CandidateExperience, data: apiDataExperience, icon: <Briefcase size={18} /> },
                            { id: "Education", displayName: "Education History", component: CandidateEducation, data: apiDataEducation, icon: <BookOpen size={18} /> },
                            { id: "Skills", displayName: "Skills", component: CandidateSkill, data: apiDataSkill, icon: <Star size={18} /> },
                            { id: "Certificates", displayName: "Certificates", component: CandidateCertificate, data: apiCandidateCertificate, icon: <Award size={18} /> },
                            { id: "Resume", displayName: "Resume", component: CandidateResume, data: apiCandidateResume, icon: <FileText size={18} /> },
                            { id: "Socials", displayName: "Socials", component: CandidateSocials, data: apiDataSocial, icon: <Share2 size={18} /> }
                        ].map(({ id, displayName, component: Component, data, icon }) => (
                            <div className="accordion-item mb-5" key={id}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${id}`} aria-expanded="false" aria-controls={`collapse${id}`}>
                                        {icon} <span className="ms-2">{displayName}</span>
                                    </button>
                                </h2>
                                <div id={`collapse${id}`} className="accordion-collapse collapse" data-bs-parent="#profileAccordion">
                                    <div className="accordion-body">
                                        {isLoading ? <SkeletonLoader /> : <Component candidateID={apiCandidateID} candidateData={data} />}
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Need Help?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="topic">
                            <Form.Label>Topic</Form.Label>
                            <Form.Select value={topic} onChange={(e) => setTopic(e.target.value)} required>
                                <option value="" disabled>
                                    Please select
                                </option>
                                <option>Sign in / Password</option>
                                <option>Report suspicious ad</option>
                                <option>Applying for a job</option>
                                <option>My Account</option>
                                <option>Technical support</option>
                                <option>Other</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="email" className="mt-3">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="message" className="mt-3">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                maxLength={1000}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Describe your issue..."
                                required
                            />
                            <small className="text-muted">{1000 - message.length} characters remaining</small>
                        </Form.Group>

                        {error && <div className="alert alert-danger mt-3">{error}</div>}
                        {success && <div className="alert alert-success mt-3">{success}</div>}

                        <div className="d-flex justify-content-end mt-3">
                            <Button type="submit" variant="primary" disabled={loading} className="btn btn-sm btn-primary me-2 save-personInfo">
                                {loading ? "Sending..." : "Send Request"}
                            </Button>
                            <Button variant="secondary" onClick={() => setShowModal(false)} className="btn btn-sm btn-neutral me-2 cancel-personInfo">Cancel</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

        </>

    );
}

export default Profile;
