import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import logo from '../images/stacktalent-708X214.jpg';
import { Link } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";

function HomeHeader() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const handleLogin = () => {
        if (!instance) return;
        instance.loginRedirect({
            scopes: ["https://talentupuat.onmicrosoft.com/9d7a2773-5f60-4af3-ac89-a8f9ee98250a/candidate.read"],
            redirectUri: `${window.location.origin}/auth/callback`,
        });
    };

    const handleLogout = () => {
        if (!instance) return;
        instance.logoutRedirect({
            postLogoutRedirectUri: window.location.origin,
            account: instance.getActiveAccount(),
        });
    };


    return (
        <header>
            <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow">
                <div className="container-fluid  container-index-w234">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} className="csshomelogo" alt="Stacktalent logo"></img>
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse d-sm-inline-flex justify-content-between">
                        <div className="navbar-tu-inn ms-auto">
                            <ul className="navbar-nav flex-grow-1">
                                <li className="nav-item">
                                    <Link className="nav-link text-dark" to="/">Find Jobs</Link>
                                </li>

                                {isAuthenticated ? (
                                    <>
                                        <li className="nav-item">
                                            <Link className="nav-link text-dark" to="/candidate/profile">Profile</Link>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-dark" href="#" onClick={handleLogout}>Log Out</a>
                                        </li>
                                    </>
                                ) : (
                                    <li className="nav-item">
                                        <a className="nav-link text-dark" href="#" onClick={handleLogin}>Sign In</a>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <a className="nav-link text-dark" href="https://business.talentup.com.au">Employers / Post Job</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default HomeHeader;
