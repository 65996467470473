// Header.js
import React from 'react';
import logoImage from '../../src/images/stacktalent-708X214-white.png';


function HomeFooter() {
    return (
        <div className="g-6 foo_ls_lskui">
            <div className="footer-container text-center py-4">
                <div className="container">
                    {/* Logo */}
                    <div className="footer-logo mb-3">
                        <img src={logoImage} className="footer-img" alt="Stacktalent.com.au - The IT job marketplace" />
                    </div>

                    {/* Links */}
                    <div className="footer-links d-flex justify-content-center gap-4 mb-3">
                        <a href="/candidate/profile" className="nav-link text-body-primary">Sign In</a>
                        <a href="https://stacktalent.com.au" className="nav-link text-body-primary">Find Jobs</a>
                    </div>

                    {/* Copyright + Privacy */}
                    <div className="footer-text">
                        <p className="mb-2">
                            &copy; {new Date().getFullYear()} Stacktalent.com.au - <a href="#">Privacy</a>
                        </p>
                    </div>

                    {/* Social Icons */}
                    <div className="social-icons">
                        <a className="px-2" href="#"><i className="bi bi-facebook"></i></a>
                        <a className="px-2" href="#"><i className="bi bi-instagram"></i></a>
                        <a className="px-2" href="#"><i className="bi bi-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeFooter;
