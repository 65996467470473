import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import { loginRequest } from "../utils/authConfig";
import { InteractionStatus } from "@azure/msal-browser";

const ProtectedRoute = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();
    const location = useLocation();
    const [redirecting, setRedirecting] = useState(false);

    useEffect(() => {
        const doLoginRedirect = async () => {
            // If MSAL has finished loading and no accounts are found
            if (inProgress === InteractionStatus.None && accounts.length === 0 && !redirecting) {
                sessionStorage.setItem("postLoginRedirect", location.pathname);
                setRedirecting(true);
                await instance.loginRedirect({
                    ...loginRequest,
                    redirectUri: `${window.location.origin}/auth/callback`,
                });
            }
        };

        doLoginRedirect();
    }, [inProgress, accounts, location.pathname, redirecting, instance]);

    if (inProgress !== InteractionStatus.None || accounts.length === 0 || redirecting) {
        return <div className="loadingStyle">Loading authentication...</div>;
    }

    return children;
};

export default ProtectedRoute;




//import React, { useEffect, useState } from "react";
//import { useMsal } from "@azure/msal-react";
//import { useLocation, Navigate } from "react-router-dom";
//import { loginRequest } from "../utils/authConfig";
//import { InteractionStatus } from "@azure/msal-browser";

//const ProtectedRoute = ({ children }) => {
//    const { instance, accounts, inProgress } = useMsal();
//    const location = useLocation();
//    const [loading, setLoading] = useState(true);

//    useEffect(() => {
//        const initializeAuth = async () => {
//            if (inProgress !== "none") {
//                console.log("MSAL still initializing...");
//                return;
//            }

//            if (accounts.length === 0) {
//                console.log("No authenticated user, redirecting to login...");
//                sessionStorage.setItem("postLoginRedirect", location.pathname);
//                await instance.loginRedirect({
//                    ...loginRequest,
//                    redirectUri: `${window.location.origin}/auth/callback`,
//                });
//            } else {
//                setLoading(false);
//            }
//        };

//        initializeAuth();
//    }, [accounts, instance, inProgress]);

//    if (loading || inProgress !== "none") {
//        return <div className="loadingStyle">Redirecting...</div>;
//    }

//    return accounts.length > 0 ? children : <Navigate to="/auth/callback" state={{ from: location }} />;
//};

//export default ProtectedRoute;

