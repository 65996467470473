import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ResumeAddForm from './ResumeAdd';
import CoverAddForm from './CoverAdd';
import CandidatePersonalDetails from './CandidatePersonalDetails'; 
import { useParams } from 'react-router-dom';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { TokenContext } from '../../Context/TokenContext';
import { getApiBaseUrl } from '../../utils/Config';
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../../Context/AlertContext';
import Alert from '../../utils/GenAlert';

const CandidateResumeApply = ({ candidateID, candidateDataResume, jobQuestions, candidateJobTitle, candidateDataCover, candidateBusName, candidateBusID, apiDataDetails, refetchCandidateDetails }) => {
    const isAuthenticated = useIsAuthenticated();
    const { token } = useContext(TokenContext);
    const { showAlert } = useContext(AlertContext);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showAddCLForm, setShowAddCLForm] = useState(false);
    const [resumeItems, setResumeItems] = useState(candidateDataResume || []);
    const [coverItems, setCoverItems] = useState(candidateDataCover || []);
    const [answers, setAnswers] = useState({});
    const [selectedResume, setSelectedResume] = useState('');
    const [saving, setSaving] = useState(false);
    const [selectedCover, setSelectedCover] = useState('');
    const [isCandidateDetailsIncomplete, setIsCandidateDetailsIncomplete] = useState(true);
    const { jobId } = useParams();
    const baseUrl = getApiBaseUrl();
    const navigate = useNavigate();
    // Check if candidate details are incomplete
    useEffect(() => {
        setIsCandidateDetailsIncomplete(
            !apiDataDetails || !apiDataDetails.CurrentRole || !apiDataDetails.Citizenship || !apiDataDetails.Location
        );
    }, [apiDataDetails]);
    const handleDetailsUpdated = () => {
        refetchCandidateDetails();
    };
    const handleResumeSelect = (event) => {
        setSelectedResume(event.target.value);
    };
    const handleCoverSelect = (event) => {
        setSelectedCover(event.target.value);
    };
    const handleAddCancelClick = () => {
        setShowAddForm(false);
    };
    const handleAddCancelCLClick = () => {
        setShowAddCLForm(false);
    };
    const updateResumeItems = (newResumeItem) => {
        setResumeItems((prevItems) => [...prevItems, newResumeItem]);
        setShowAddForm(false);
    };
    const updateCoverItems = (newCoverItem) => {
        setCoverItems((prevItems) => [...prevItems, newCoverItem]);
        setShowAddCLForm(false);
    };

    const handleAnswerChange = (questionId, answer) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: answer, // Simply update state, no validation here
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
       
        // Ensure candidate details are complete before proceeding
        if (isCandidateDetailsIncomplete) {
            showAlert('Please complete your profile details before applying for this job.', false);
            return;
        }

        if (!selectedResume) {
            showAlert('Please select a file before submitting', false);
            return;
        }

        // Validate job question answers
        const regex = /^[a-zA-Z0-9\s]+$/; // Allow only letters, numbers, and spaces
        let hasErrors = false;
        let questionErrors = [];

        jobQuestions.forEach((q, index) => {
            const answer = answers[q.jobPostQuestionID]?.trim() || "";

            if (!answer) {
                hasErrors = true;
                questionErrors.push(`Question ${index + 1}: This field is required.`);
            } else if (!regex.test(answer)) {
                hasErrors = true;
                questionErrors.push(`Question ${index + 1}: Only letters, numbers, and spaces are allowed.`);
            }
        });

        if (hasErrors) {
            showAlert(
                <ul className="text-danger">
                    {questionErrors.map((err, i) => (
                        <li key={i}>{err}</li>
                    ))}
                </ul>,
                false
            );
            return;
        }
        setSaving(true);
        const questionAnswers = jobQuestions.map((q) => ({
            JobPostQuestionID: q.jobPostQuestionID,
            AnswerText: answers[q.jobPostQuestionID] || "",
            QuestionText: "NA",
            CreatedBy: 'Admin',
            ModifiedBy: 'User',
        }));
        const payload = {
            AppliedJob: {
                CandidateID: candidateID,
                BusinessID: candidateBusID,
                JobPostID: jobId,
                Title: candidateJobTitle,
                ResumeID: selectedResume,
                CoverLetterID: selectedCover || null,
                ApplyDate: new Date().toISOString(),
                BusinessName: candidateBusName,
                CreatedBy: 'Admin',
                ModifiedBy: 'User',
                IsDeleted: false,
            },
            //...(questionAnswers.length > 0 && { QuestionAnswers: questionAnswers }), // Only include if not empty

           QuestionAnswers: questionAnswers,
        };
        try {
            const response = await fetch(
                `${baseUrl}/api/AppliedJob/Upsert`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                }
            );
            const responseData = await response.json();

            if (responseData.success) {
                showAlert('Application submitted successfully!', true);
                setTimeout(() => {
                    navigate(-2);
                }, 3000);
            } else {
                const formattedErrors = formatErrorMessages(responseData.message);
                showAlert(formattedErrors, false);
            }
        }
        catch (error) {
            showAlert('Oops, something went wrong. Please try again later.', false);
        }
        finally {
            setSaving(false);
        }
    };

    const formatErrorMessages = (errorMessage) => {
        if (!errorMessage) return "An unknown error occurred.";

        // Split error messages by semicolon or other separators
        let errors = errorMessage.split(";").map(err => err.trim());

        // Extract meaningful parts
        errors = errors.map(err => {
            const match = err.match(/QuestionAnswers\[(\d+)\]\.AnswerText: (.*)/);
            return match ? `Question ${parseInt(match[1], 10) + 1}: ${match[2]}` : err;
        });

        // Convert to a bullet-point list
        return (
            <ul className="text-danger">
                {errors.map((err, index) => (
                    <li key={index}>{err}</li>
                ))}
            </ul>
        );
    };

    useEffect(() => {
        if (candidateDataResume) {
            setResumeItems(candidateDataResume);
        }
    }, [candidateDataResume]);

    useEffect(() => {
        if (candidateDataCover) {
            setCoverItems(candidateDataCover);
        }
    }, [candidateDataCover]);

    useEffect(() => {
        setIsCandidateDetailsIncomplete(
            !apiDataDetails || !apiDataDetails.CurrentRole || !apiDataDetails.Citizenship || !apiDataDetails.Location
        );
    }, [apiDataDetails]);

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    if (!candidateDataResume && !candidateDataResume) {
        return <SkeletonLoader />;
    }

    return (
        <>
            <AlertContext.Consumer>
                {({ alert }) => (
                    <>
                        {alert.visible && <Alert message={alert.message} success={alert.success} />}
                    </>
                )}
            </AlertContext.Consumer>
          

            {isCandidateDetailsIncomplete && (
                <div className="vstack gap-6 resume-create-k_idj mt-7 css-dlxf46">
                    <div className="card">
                        <div className="card-header">
                            <h5>We are missing important information from you</h5>
                        </div>
                        <div className="card-body">
                            <CandidatePersonalDetails candidateID={candidateID} candidateData={apiDataDetails} onDetailsUpdated={handleDetailsUpdated} />
                        </div>
                    </div>
                </div>
            )}

            <div className="vstack gap-6 resume-create-k_idj mt-7 css-dlxf46">

                <div className="card">
                    <div className="card-header">
                        <h5>Resume</h5>
                    </div>
                    <div className="card-body">
                        <div className="col-md-12 mb-2">
                            <div className="row gx-4">
                                <div className="col">
                                    <div className="resume-select-box">
                                        <label htmlFor="resumeSelect">Select a Resume:</label>
                                        <select
                                            id="resumeSelect"
                                            className="form-select"
                                            value={selectedResume}
                                            onChange={handleResumeSelect}
                                            disabled={isCandidateDetailsIncomplete} // Disable if details are incomplete
                                        >
                                            <option value="">--Select Resume--</option>
                                            {resumeItems.map((resume) => (
                                                <option key={resume.AttachmentID} value={resume.AttachmentID}>
                                                    {resume.FileName} {resume.IsLatest ? '- Latest' : ''}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-auto align-self-end">
                                    <span className="d-inline-block py-3 font-semibold text-muted">or</span>
                                </div>
                                <div className="w-100 d-block d-sm-none"></div> 

                                <div className="col-auto align-self-end margin-bottom-mobile-20">
                                    <button type="button" className="btn btn-primary profile-button-w200" onClick={() => setShowAddForm(true)} disabled={isCandidateDetailsIncomplete}>
                                        <i className="bi bi-plus-lg"></i>
                                        <span className="d-none d-sm-inline ms-2">Upload New CV</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {showAddForm && (
                            <ResumeAddForm
                                candidateID={candidateID}
                                token={token}
                                onCancel={handleAddCancelClick}
                                updateResumeItems={updateResumeItems}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="vstack gap-6 resume-create-k_idj mt-7 css-dlxf46">
                <div className="card">
                    <div className="card-header">
                        <h5>Cover Letter</h5>
                    </div>
                    <div className="card-body">
                        <div className="col-md-12 mb-2">
                            <div className="row gx-4">
                                <div className="col">
                                    <div className="cover-select-box">
                                        <label htmlFor="coverSelect">Select a Cover Letter:</label>
                                        <select
                                            id="coverSelect"
                                            className="form-select"
                                            value={selectedCover}
                                            onChange={handleCoverSelect}
                                            disabled={isCandidateDetailsIncomplete} // Disable if details are incomplete
                                        >
                                            <option value="">--Select Cover Letter--</option>
                                            {coverItems.map((cover) => (
                                                <option key={cover.AttachmentID} value={cover.AttachmentID}>
                                                    {cover.FileName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-auto align-self-end">
                                    <span className="d-inline-block py-3 font-semibold text-muted">or</span>
                                </div>
                                <div className="w-100 d-block d-sm-none"></div> 

                                <div className="col-auto align-self-end">
                                    <button type="button" className="btn btn-primary profile-button-w200 margin-bottom-mobile-20" onClick={() => setShowAddCLForm(true)} disabled={isCandidateDetailsIncomplete}>
                                        <i className="bi bi-plus-lg"></i>
                                        <span className="d-none d-sm-inline ms-2">Upload Cover Letter</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {showAddCLForm && (
                            <CoverAddForm
                                candidateID={candidateID}
                                token={token}
                                onCancel={handleAddCancelCLClick}
                                updateCoverItems={updateCoverItems}
                            />
                        )}
                    </div>
                </div>
            </div>

            {jobQuestions?.length > 0 && (
                <div className="vstack gap-6 resume-create-k_idj mt-7 css-dlxf46">
                    <div className="card">
                        <div className="card-header">
                            <h5>Employer Questions</h5>
                        </div>
                        <div className="card-body">
                            <div className="col-md-12 mb-2">
                                <div className="row gx-4">
                                    <div className="col">
                                        <div className="cover-select-box">
                                            <p>Please answer the questions below:</p>
                                            <div className="job_post_questions">
                                                <h5>Employer Questions</h5>
                                                <ul>
                                                    {jobQuestions?.length > 0 ? (
                                                        jobQuestions.map((question) => (
                                                            <li key={question.jobPostQuestionID}>
                                                                <div className="mb-3">
                                                                    <label>{question.questionText}</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Your answer"
                                                                        maxLength="100" // Just UI restriction, actual validation happens in submit
                                                                        onChange={(e) => handleAnswerChange(question.jobPostQuestionID, e.target.value)}
                                                                    />

                                                                </div>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li>No questions available.</li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="col-12 text-center mt-2">
                <button
                    type="submit"
                    className="btn btn-primary profile-button-w200"
                    disabled={!selectedResume || saving || isCandidateDetailsIncomplete} // Disable if details are incomplete
                    onClick={handleSubmit}
                >
                    {saving ? 'Saving...' : 'Submit'}
                </button>
            </div>
        </>
    );
};

export default CandidateResumeApply;
