import React, { useState, useEffect } from 'react';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useIsAuthenticated } from "@azure/msal-react";


const CandidateBusCertificate = ({ candidateData }) => {
    const isAuthenticated = useIsAuthenticated();
    const [certificateItems, setCertificateItems] = useState(candidateData || []);

    function formatDate(dateTimeString) {
        const dateObj = new Date(dateTimeString);
        return `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;
    }
  
    useEffect(() => {
        if (candidateData) {
            setCertificateItems(candidateData);
        }
    }, [candidateData]);

   

    useEffect(() => {
    }, [certificateItems]);

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    if (!candidateData) {
        return <SkeletonLoader />;
    }
    return (
        <>
            {certificateItems.map((certificate, index) => {
                return (
                    <div className="certificate-item" key={certificate.AttachmentID}>
                        <div className="bnasdf_asldf certificate-item-read">
                            <div className="card-body car-dlj-ds d-flex justify-content-between align-items-center">
                                <div>
                                    <h5 className="d-block h5 font-semibold mb-1">{certificate.FileName} </h5>
                                </div>
                                <div className="text-end mx-n2 d-flex align-items-center">
                                    <a href={certificate.Uri} target="_blank" rel="noreferrer" className="download-but btn me-2-15"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>

    );

}

export default CandidateBusCertificate;
