import React, { useState,useContext } from 'react';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useIsAuthenticated } from "@azure/msal-react";
import { CandidateContext } from '../../Context/CandidateContext';

function SkillsForm({ candidateData, displayResponseMessage, onCancel, handleUpdate }) {
    const isAuthenticated = useIsAuthenticated();
    const { refetchCandidateDetails } = useContext(CandidateContext)
    const { token, idTokenClaims } = useContext(TokenContext);
    const baseUrl = getApiBaseUrl();
    const { showAlert } = useContext(AlertContext);

    const [saving, setSaving] = useState(false);
    const [formData, setFormData] = useState({
        Name: candidateData.Name,
        YearsExperience: candidateData.YearsExperience,
        SkillID: candidateData.SkillID?.toString() || ''
    });

    const [errors, setErrors] = useState({
        Name: '',
        YearsExperience: '',

    });
    const handleInputChange = (event, fieldName) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    //Validation
    const validate = () => {
        const newErrors = {};

        // Allow letters, numbers, spaces, dashes, plus, hash, periods, pipes, and commas
        const allowedRegex = /^[a-zA-Z0-9\s#.+-,|()]+$/;

        if (!formData.Name) {
            newErrors.Name = 'Skill is required.';
        } else if (!allowedRegex.test(formData.Name)) {
            newErrors.Name = 'Skill name contains invalid characters.';
        } else if (formData.Name.length > 100) {
            newErrors.Name = 'Skill name should not exceed 100 characters.';
        }

        if (!formData.YearsExperience) {
            newErrors.YearsExperience = 'Years of Experience is required.';
        }

        setErrors(newErrors);
        return Object.values(newErrors).every(error => !error);
    };




    //Submit to API
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            if (saving) {
                return;
            }
            setSaving(true);

            const dataToPost = {
                Name: formData.Name,
                YearsExperience: formData.YearsExperience,
                Description: 'None',
                SkillLevelTypeID: 1,
                // Set other properties here
                CandidateID: candidateData.CandidateID,
                SkillID: candidateData.SkillID,
                DateTimeCreated: new Date().toISOString(),
                DateTimeModified: new Date().toISOString(),
                CreatedBy: 'Admin',
                ModifiedBy: 'User',
                IsDeleted: false,
                B2CObjectId: idTokenClaims.oid,
            };

            try {
                const response = await fetch(
                    `${baseUrl}/api/Skill/Upsert`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(dataToPost),
                    }
                );
                const responseData = await response.json();
                if (responseData.success) {
                    //console.log(responseData);
                    //handleUpdate(dataToPost);
                    showAlert('Success', true);
                    refetchCandidateDetails(); 
                } else {
                    showAlert('Oops, something went wrong. Please try again later.', false);
                }
            }
            catch (error) {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
            finally {
                setSaving(false);
            }
        }
    };
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    return (
        <div className="skill-create-form">
            <form onSubmit={handleSubmit}>
                <div className="skill-item-edit">
                    <div className="skill-edit card-body-iajj">

                        <input type="hidden" value={formData.SkillID} name="SkillID" readOnly />
                        <input type="hidden" value={formData.CandidateID} name="candidateID" readOnly />

                        <div className="row g-5">
                            <div className="col-md-6">
                                <label className="form-label">Skill</label>
                                <input type="text" className="form-control" value={formData.Name} name="Name" onChange={e => handleInputChange(e, 'Name')} />
                                {errors.Name && <small className="text-danger">{errors.Name}</small>}
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Years Experience</label>
                                <select className="form-select" value={formData.YearsExperience} name="YearsExperience" id="YearsExperienceSelect" onChange={e => handleInputChange(e, 'YearsExperience')}>
                                    <option value="">--Select--</option>
                                    <option value="0 - 1">0 to 1 Years</option>
                                    <option value="1 - 3">1 to 3 Years</option>
                                    <option value="3 - 5">3 to 4 Years</option>
                                    <option value="5 - 7">5 to 7 Years</option>
                                    <option value="7+">7+ Years</option>
                                </select>
                                {errors.YearsExperience && <small className="text-danger">{errors.YearsExperience}</small>}

                            </div>
                       
                            <div className="col-12 text-end">
                                <button type="submit" className="btn btn-sm me-2 btn-primary" disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>
                                <button type="button" className="btn btn-sm btn-neutral me-2" onClick={onCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SkillsForm;
