import React, { useCallback, useState, useContext } from 'react';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useDropzone } from 'react-dropzone';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useIsAuthenticated } from "@azure/msal-react";
import { CandidateContext } from "../../Context/CandidateContext";

const CandidateProfilePicture = ({ candidateData, profilePic, candidateID }) => {
    const isAuthenticated = useIsAuthenticated();

    const { token, idTokenClaims } = useContext(TokenContext);
    const profilepicuri = profilePic && typeof profilePic === 'object' ? profilePic.Uri : profilePic;
    const [preview, setPreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [saving, setSaving] = useState(false);
    const baseUrl = getApiBaseUrl();
    const { refetchCandidateImage } = useContext(CandidateContext);
    const { showAlert } = useContext(AlertContext);
    const reactQuillMaxLength = 300;
    const onFileChange = useCallback((acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setSelectedFile(file);
            const objectURL = URL.createObjectURL(file);
            setPreview(objectURL);
        }

        if (fileRejections.length > 0) {
            fileRejections.forEach(rejection => {
                if (rejection.errors.some(error => error.code === 'file-too-large')) {
                    showAlert(`File "${rejection.file.name}" is too large.`, false);
                } else if (rejection.errors.some(error => error.code === 'file-invalid-type')) {
                    showAlert(`File "${rejection.file.name}" has an invalid type.`, false);
                } else {
                    showAlert(`File "${rejection.file.name}" was rejected.`, false);
                }
            });
        }
    }, []);


    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onFileChange,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/bmp' : ['.bmp']
        }, 
        multiple: false,
        maxSize: 2 * 1024 * 1024  // Limit to 2MB
    });
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                console.log("Base64 Loaded:", reader.result);
                resolve(reader.result.split(",")[1]);
            };
            reader.onerror = error => {
                console.error("Error while reading the file:", error);
                reject(error);
            };
        });
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedFile) {
            showAlert('Please select a file before submitting', false);
            return;
        }
        setSaving(true);

        const base64Image = await resizeImage(selectedFile, 200, 200);
        if (!base64Image) {
            showAlert('Whoops, something went wrong with your image. Please try again.', false);
            return;
        }
        const payload = {
            FIleBase64: base64Image,
            CandidateID: candidateID,
            DateTimeCreated: new Date().toISOString(),
            DateTimeModified: new Date().toISOString(),
            CreatedBy: 'Admin',
            ModifiedBy: 'User',
            IsDeleted: false,
            B2CObjectId: idTokenClaims.oid,
            AttachmentTypeId: 1,
            FileName: selectedFile.name
        };
        try {
            const response = await fetch(
                `${baseUrl}/api/Attachment/UploadCandidateAttachment`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                }
            );
            const responseData = await response.json();

            if (responseData.success) {
                showAlert('Profile picture updated successfully.', true);
                setPreview(null); // Clear the preview after a successful save
                refetchCandidateImage(); // Force refresh the profile picture from API
            } else {
                const data = await response.json();
                showAlert(data.message || 'Oops, something went wrong. Please try again later.', false);
            }
        }
        catch (error) {
            showAlert('Oops, something went wrong. Please try again later.', false);
        }
        finally {
            setSaving(false);
        }
    };
    const resizeImage = (file, maxWidth, maxHeight) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    const ctx = canvas.getContext('2d');

                    // Fill background with white to prevent black transparency issues
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, canvas.width, canvas.height);

                    // Draw image over the white background
                    ctx.drawImage(img, 0, 0, width, height);

                    // Convert to PNG (or keep original format)
                    const dataUrl = canvas.toDataURL('image/png'); // Preserve PNG format

                    resolve(dataUrl.split(',')[1]); // Return base64 without the header
                };
                img.onerror = (err) => reject(err);
            };
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(file);
        });
    };
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    if (!candidateData) {
        return <SkeletonLoader />;
    }
    return (
        <form id="ProfilePic-add-form" onSubmit={handleSubmit}>
            <div className="col-auto image-upload" {...getRootProps()}>
                <input name="File" {...getInputProps()} />
                <label htmlFor="file_upload_pp">
                    {profilepicuri || preview ? (
                        // Show this div if there is a profilePicUrl or a preview image
                        <div className="PlaceHolder-Sub-PP">
                            <img
                                id="ProPic"
                                src={preview || profilepicuri}
                                alt={`${candidateData.FirstName} profile picture`}
                            />
                        </div>
                    ) : (
                        // Show this div if there is NO profilePicUrl and NO preview
                        <div className="PlaceHolder-Sub-PP-TX">
                            <div className="initials-placeholder">
                                    {candidateData.FirstName[0].toUpperCase()}
                                    {candidateData.LastName[0].toUpperCase()}
                            </div>
                        </div>
                    )}
                </label>

            </div>
            {selectedFile && <button type="submit" className="btn btn-sm btn-primary upload-btn" disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>}
        </form>
    );
}

export default CandidateProfilePicture;
