// Header.js
import React, { useState, useEffect, useRef } from 'react';
import { Outlet, Link } from "react-router-dom";
import JobSearch from '../pages/JobSearch';
import jobHunt from '../../src/images/job_hunt.svg';
import candidateHire from '../../src/images/hiring.svg';
// Technology SVGs
import reactImage from '../../src/svg/technologies/React.svg';
import bitBucketImage from '../../src/svg/technologies/BitBucket.svg';
import sqlImage from '../../src/svg/technologies/Microsoft-SQL-Server.svg';
import csharpImage from '../../src/svg/technologies/CSharp.svg';
import KubernetesImage from '../../src/svg/technologies/Kubernetes.svg';
import DockerImage from '../../src/svg/technologies/Docker.svg';
import LinuxImage from '../../src/svg/technologies/Linux.svg';
import mongoDB from '../../src/svg/technologies/MongoDB.svg';
import pythonImage from '../../src/svg/technologies/Python.svg';
import rustImage from '../../src/svg/technologies/Rust.svg';
import awsImage from '../../src/svg/technologies/AWS.svg';
import illustratorImage from '../../src/svg/technologies/Adobe-Illustrator.svg';
import photoshopImage from '../../src/svg/technologies/Adobe-Photoshop.svg';
import ciscoImage from '../../src/svg/technologies/Cisco_logo.svg';

function HomeBody() {
    const carouselRef = useRef(null);

    const categories = [
        { name: "Network and Infrastructure", icon: "/path-to-icons/network.png" },
        { name: "Data Science and Analytics", icon: "/path-to-icons/data-science.png" },
        { name: "Cybersecurity", icon: "/path-to-icons/cybersecurity.png" },
        { name: "IT Project Management", icon: "/path-to-icons/project-management.png" },
        { name: "UI/UX Design", icon: "/path-to-icons/design.png" },
        { name: "Tech Support and Helpdesk", icon: "/path-to-icons/support.png" },
        // Add more categories as needed
    ];

    const categoryMap = {
        "Artificial Intelligence and Machine Learning": 1,
        "Business Intelligence and Data Analysis": 2,
        "Cloud Computing and Architecture": 3,
        "Cybersecurity": 4,
        "Cybersecurity and Information Security": 4, // handle full name too
        "Database Administration and Development": 5,
        "Data Science and Analytics": 6,
        "DevOps Engineering": 7,
        "Executive Leadership (e.g., CIO, CTO)": 8,
        "IT Consulting and Strategy": 9,
        "IT Education and Training": 10,
        "IT Management": 11,
        "IT Project Management": 12,
        "IT Research and Development": 13,
        "IT Support and Helpdesk": 14,
        "Tech Support and Helpdesk": 14,
        "Mobile Application Development": 15,
        "Network and Infrastructure": 16,
        "Quality Assurance and Testing": 17,
        "Software Development and Engineering": 18,
        "System Administration": 19,
        "Technical Writing and Documentation": 20,
        "UI/UX Design": 21,
        "Web Development and Design": 22,
    };


    const techLogos = [
        { name: 'React', icon: reactImage },
        { name: 'BitBucket', icon: bitBucketImage },
        { name: 'Sql Server', icon:  sqlImage  },
        { name: 'C#', icon:  csharpImage  },
        { name: 'Kubernetes', icon:  KubernetesImage  },
        { name: 'Docker', icon:  DockerImage  },
        { name: 'Linux', icon:  LinuxImage  },
        { name: 'Mongo DB', icon: mongoDB },
        { name: 'Adobe Illustrator', icon: illustratorImage },
        { name: 'Python', icon: pythonImage },
        { name: 'Rust', icon: rustImage },
        { name: 'Cisco', icon: ciscoImage },
        { name: 'Adobe Photoshop', icon: photoshopImage },
        { name: 'AWS', icon: awsImage },
    ];
    // Auto-scroll effect (optional)
    useEffect(() => {
        const interval = setInterval(() => {
            if (carouselRef.current) {
                carouselRef.current.scrollLeft += 200; // Adjust scroll speed
                if (
                    carouselRef.current.scrollLeft >=
                    carouselRef.current.scrollWidth - carouselRef.current.clientWidth
                ) {
                    carouselRef.current.scrollLeft = 0;
                }
            }
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <JobSearch />
            <div className="container-xxl homebodytoplayer">
                <section className="HuSection container-fluid hilaki">
                    <div className="HuSection_createprofile_l_lasd p-3">
                        <h2 className="title_vt">{"{Your Next Tech Job Awaits}"}</h2>
                        <p>Discover your dream role in the tech industry with Stacktalent. We connect you with technology jobs that align with your skills and career aspirations. Start exploring opportunities to propel your career forward.</p>
                        <div className="HuSection_bo_blocy mt-4">
                            <a href="/candidate/profile" className="btn btn-primary">Create Your Profile</a>
                        </div>
                    </div>
                    <div className="HuSection_createprofile_r_lasd">
                        <div className="HuSection_createprofile_l_POlj">
                            <img src={jobHunt} alt="home page create profile" />
                        </div>
                    </div>
                </section>

                <div className="mid-but-tog container-fluid">
                    <section className="homepage-content-wrapper-outer">
                        <div className="homepage-content-wrapper-inner">
                            <p className="title_wt">Categories</p>
                            <p className="expl_kla_lso mb-4 text-center">Browse job listings across a wide range of categories. Whether you're an experienced professional or just starting out, we have something for everyone.</p>
                            <div className="row g-4 justify-content-center">
                                {categories.map((category, index) => (
                                    <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                                        <div className="card category-card h-100">
                                            {/* Optional icon display: <img src={category.icon} className="card-img-top" alt={category.name} /> */}
                                            <div className="card-body light-tal-pur-back">
                                                <h5 className="card-title">{category.name}</h5>
                                                <p className="card-text mb-2 expl_kla">
                                                    Explore jobs and opportunities in {category.name}.
                                                </p>
                                                <Link
                                                    to={`/jobs/?categoryId=${categoryMap[category.name] || ''}`}
                                                    className="btn btn-tertiary"
                                                >
                                                    View Jobs
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        
                        </div>
                    </section>
                </div>
                <div className="tech-carousel-container mt-8 mb-8">
                    <h3 className="tech-title"> Candidates: Showcase Your Skills </h3>
                    <div className="tech-carousel" ref={carouselRef}>
                        {techLogos.map((tech, index) => (
                            <div className="tech-item" key={index}>
                                <img src={tech.icon} alt={tech.name} className="tech-icon" />
                                <p className="tech-name">{tech.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <section className="HuSection container-fluid hilaki">
                    <div className="HuSection_createprofile_r_lasd p-3">
                        <div className="HuSection_createprofile_l_POlj">
                            <img src={candidateHire} alt="home page create profile" />
                        </div>
                    </div>
                    <div className="HuSection_createprofile_l_lasd p-3">
                        <h2 className="title_vt">Employers: Find Top Tech Talent</h2>
                        <p>At Stacktalent, we streamline the hiring process. Our platform connects you with skilled professionals ready to make an impact. Post your job openings today and meet your next great hire.</p>
                        <div className="HuSection_bo_blocy mt-4">
                            <a href="https://stacktalent.com.au/business/dashboard" className="btn btn-primary m-1">Post a job</a>
                            <a href="https://stacktalent.com.au/business/dashboard" className="btn btn-primary m-1">Hire top tech talent</a>
                        </div>
                    </div>
                  
                </section>

            </div>
        </>
    );
}

export default HomeBody;
