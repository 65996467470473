import { useState, useEffect } from 'react';
import { getApiBaseUrl } from '../utils/Config';

function useProfilePic(token, apiCandidateID, refetchTrigger) {
    const [profilePicUrl, setProfilePicUrl] = useState(null);
    const baseUrl = getApiBaseUrl();

    useEffect(() => {
        if (token && apiCandidateID) {
            const fetchProfilePic = async () => {
                try {
                    const response = await fetch(
                        `${baseUrl}/api/Attachment/GetCandidateProfilePicture?candidateId=${apiCandidateID}`,
                        {
                            mode: 'cors',
                            headers: {
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    );
                    const data = await response.json();
                    // Assuming the returned data has a property that contains the URL, adjust as necessary
                    setProfilePicUrl(data.result);

                } catch (error) {
                    console.error("Failed to fetch profile pic URL:", error);
                }
            };

            fetchProfilePic();
        }
    }, [token, apiCandidateID, baseUrl, refetchTrigger]);

    return profilePicUrl;
}

export default useProfilePic;
