import React, { useState,useContext } from 'react';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useIsAuthenticated } from "@azure/msal-react";
import { CandidateContext } from '../../Context/CandidateContext';

function EducationAddForm({ updateEducationItems, candidateID, onCancel }) {
    const isAuthenticated = useIsAuthenticated();
    const { refetchCandidateDetails } = useContext(CandidateContext)
    const { token, idTokenClaims } = useContext(TokenContext);
    const [saving, setSaving] = useState(false);
    const baseUrl = getApiBaseUrl();
    const { showAlert } = useContext(AlertContext);

    const [formData, setFormData] = useState({
        School: '',
        Qualification: '',
        StartDate: '',
        EndDate: '',
    });
    const [errors, setErrors] = useState({
        School: '',
        Qualification: '',
        StartDate: '',
        EndDate: '',
    });
    const handleInputChange = (event, fieldName) => {
        const { name, type, value, checked } = event.target;
        const val = type === "checkbox" ? checked : value;

        if (fieldName === "StartDate" || fieldName === "EndDate") {
            // Ensure it's a valid date format
            if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) {
                setFormData((prevData) => ({
                    ...prevData,
                    [fieldName]: value,
                }));
                return;
            }

            const enteredDate = new Date(value);
            const year = enteredDate.getFullYear();

            if (year < 1900 || year > 2100) {
                showAlert("Please enter a valid year between 1900 and 2100.", false);
                return;
            }
        }


        setFormData(prevData => ({
            ...prevData,
            [name]: val
        }));
    };

    //Validation
    const validate = () => {
        const newErrors = {};

        // Allow only letters, numbers, spaces, dashes, pipes, commas, and periods
        const allowedRegex = /^[a-zA-Z0-9\s,'-.|]+$/;

        const formattedStartDate = formData.StartDate ? new Date(formData.StartDate).toISOString().split('T')[0] : '';
        const formattedEndDate = formData.EndDate ? new Date(formData.EndDate).toISOString().split('T')[0] : '';

        // School validation
        if (!formData.School) {
            newErrors.School = 'School is required.';
        } else if (!allowedRegex.test(formData.School)) {
            newErrors.School = 'School contains invalid characters.';
        } else if (formData.School.length > 100) {
            newErrors.School = 'School should not exceed 100 characters.';
        }

        // Qualification validation
        if (!formData.Qualification) {
            newErrors.Qualification = 'Qualification is required.';
        } else if (!allowedRegex.test(formData.Qualification)) {
            newErrors.Qualification = 'Qualification contains invalid characters.';
        } else if (formData.Qualification.length > 100) {
            newErrors.Qualification = 'Qualification should not exceed 100 characters.';
        }

        // Start date validation
        if (!formData.StartDate) {
            newErrors.StartDate = 'Start Date is required.';
        }

        // End date validation
        if (!formData.EndDate) {
            newErrors.EndDate = 'End Date is required.';
        } else if (formData.StartDate && formData.EndDate && new Date(formattedStartDate) > new Date(formattedEndDate)) {
            newErrors.EndDate = 'End Date cannot be before the Start Date.';
        }

        setErrors(newErrors);
        return Object.values(newErrors).every(error => !error);
    };



    //Submit to API
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            if (saving) {
                return;
            }
            setSaving(true);

            const dataToPost = {
                ...formData,
                CandidateID: candidateID,
                DateTimeCreated: new Date().toISOString(),
                DateTimeModified: new Date().toISOString(),
                CreatedBy: 'Admin',
                ModifiedBy: 'User',
                IsDeleted: false,
                B2CObjectId: idTokenClaims.oid,
            };

            try {
                const response = await fetch(
                    `${baseUrl}/api/Education/Upsert`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(dataToPost),
                    }
                );
                const responseData = await response.json();

                if (responseData.success) {
                    showAlert('Success', true);
                    refetchCandidateDetails(); 
                } else {
                    showAlert('Oops, something went wrong. Please try again later.', false);
                }
            }
            catch (error) {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
            finally {
                setSaving(false);
            }

        }
    };
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    return (
        <div className="education-create-form stealth-inn">
            <form onSubmit={handleSubmit}>
                <div className="education-item-edit">
                    <div className="education-edit card-body-iajj">
                        <input type="hidden" className="form-control" value={candidateID} name="candidateID" readOnly />

                        <div className="row g-5">
                            <div className="col-md-6">
                                <label className="form-label">Institution</label>
                                <input type="text" className="form-control" name="School" value={formData.School} onChange={handleInputChange} />
                                {errors.School && <small className="text-danger">{errors.School}</small>}

                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Qualification</label>
                                <input type="text" className="form-control" name="Qualification" value={formData.Qualification} onChange={handleInputChange} />
                                {errors.Qualification && <small className="text-danger">{errors.Qualification}</small>}

                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Start Date</label>
                                <input type="date" min="1900-01-01"  max="2100-12-31" className="form-control" name="StartDate" value={formData.StartDate} onChange={handleInputChange} />
                                {errors.StartDate && <small className="text-danger">{errors.StartDate}</small>}

                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Completion Date</label>
                                <input type="date" min="1900-01-01" max="2100-12-31" className="form-control" name="EndDate" value={formData.EndDate} onChange={handleInputChange} />
                                {errors.EndDate && <small className="text-danger">{errors.EndDate}</small>}

                            </div>
                            <div className="col-12 text-end">
                                <button type="submit" className="btn btn-sm btn-primary me-2" disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>
                                <button type="button" className="btn btn-sm btn-neutral me-2" onClick={onCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default EducationAddForm;
