import React, { useState, useEffect } from 'react';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useIsAuthenticated } from "@azure/msal-react";

const CandidateBusSkills = ({ candidateData }) => {
    const isAuthenticated = useIsAuthenticated();
    const [skillItems, setSkillItems] = useState(candidateData || []);

    useEffect(() => {
        if (candidateData) {
            setSkillItems(candidateData);
        }
    }, [candidateData]);

    useEffect(() => {
    }, [skillItems]);
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    if (!candidateData) {
        return <SkeletonLoader />;
    }
    return (
        <>
            {skillItems.map((skill, index) => {
                return (

                    <div className="tooltips" key={skill.SkillID}>
                        {/*  <span class="tooltiptext">{skill.YearsExperience}Years Experience</span>*/}
                        <a href="#" className="bg-purple bg-opacity-50 bg-opacity-100-hover rounded px-3 py-1 font-semibold text-white text-xs">{skill.Name}</a>
                    </div>

                );
            })}

        </>

    );

}

export default CandidateBusSkills;
