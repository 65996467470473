import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ResumeAddForm from '../../pages/Candidate/ResumeAdd';
import { TokenContext } from '../../Context/TokenContext';
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useIsAuthenticated } from "@azure/msal-react";
import { CandidateContext } from '../../Context/CandidateContext';

const CandidateResume = ({ candidateID, candidateData }) => {
    const isAuthenticated = useIsAuthenticated();
    const { token, idTokenClaims } = useContext(TokenContext);
    const [showAddForm, setShowAddForm] = useState(false);
    const [resumeItems, setResumeItems] = useState(candidateData || []);
    const [resumeIdToDelete, setResumeIdToDelete] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const baseUrl = getApiBaseUrl();
    const { showAlert } = useContext(AlertContext);
    const { refetchCandidateResume } = useContext(CandidateContext)
    const handleAddCancelClick = () => {
        setShowAddForm(false);
    };
    function formatDate(dateTimeString) {
        const trimmedDateTimeString = dateTimeString.split('.')[0];
        const dateObj = new Date(trimmedDateTimeString);
        return `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;
    }

    const updateResumeItems = (newResumeItem) => {
        setResumeItems((prevItems) => [...prevItems, newResumeItem]);
        setShowAddForm(false);

    };
    useEffect(() => {
        if (candidateData) {
            setResumeItems(candidateData);
        }
    }, [candidateData]);

    const handleDelete = async (AttachmentID) => {
        if (deleting) {
            return;
        }
        setDeleting(true);
        try {
            const response = await fetch(
                `${baseUrl}/api/Attachment/DeleteCandidateAttachment`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ AttachmentId: AttachmentID })

                }
            );
            const responseData = await response.json();
            if (responseData.success) {
                showAlert('Item deleted successfully.', true);
                refetchCandidateResume();
            }
            else {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
        } catch (error) {
            showAlert('Oops, something went wrong. Please try again later.', false);
        }
        finally {
            setDeleting(false);
            setResumeIdToDelete(null);
        }
    };

    useEffect(() => {
    }, [resumeItems]);
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <>
            {resumeItems.map((resume, index) => {
                return (
                    <div className="resume-item stealth-inn" key={resume.AttachmentID}>
                        <div className="bnasdf_asldf resume-item-read">
                            <div className="card-body d-flex justify-content-between align-items-center">
                                <div>
                                    {resume.IsLatest && (
                                        <span className="badge rounded-pill bg-success">Latest</span>
                                    )}
                                    <h5 className="d-block h5 font-semibold mb-1">{resume.FileName} </h5>

                                </div>

                                <div className="text-end mx-n2 d-flex align-items-center">
                                    <a href={resume.Uri} target="_blank" rel="noreferrer" className="download-but btn me-2-15"></a>
                                    <button className="btn me-2-15 delete-but" data-bs-target="#deleteresumeConfirm" onClick={() => setResumeIdToDelete(resume.AttachmentID)}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            {showAddForm && (
                <ResumeAddForm
                    candidateID={candidateID}
                    token={token}
                    onCancel={handleAddCancelClick}
                    updateResumeItems={updateResumeItems}
                />
            )}
            <div className="text-center skill-create-kksj">
                <button className="btn btn-primary profile-button-w200" onClick={() => setShowAddForm(true)}><i className="bi bi-plus"></i></button>
            </div>

            <Modal backdrop="true" show={resumeIdToDelete !== null} onHide={() => setResumeIdToDelete(null)} centered>
                <Modal.Header>
                    <div className="icon icon-shape rounded-3 bg-soft-primary text-danger text-lg me-4">
                        <i className="bi bi-trash"></i>
                    </div>
                    <Modal.Title>Delete, Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleDelete(resumeIdToDelete)} disabled={deleting}>
                        {deleting ? 'Deleting...' : 'Delete Now'}
                    </Button>
                    <Button variant="neutral" onClick={() => setResumeIdToDelete(null)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>

    );

}

export default CandidateResume;
