import React, {
    useReducer,
    useEffect,
    createContext,
    useContext,
    useCallback,
} from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../utils/authConfig";

const TokenContext = createContext();

const authReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                ...state,
                loading: false,
                token: action.token,
                idTokenClaims: action.idTokenClaims,
                oid: action.oid,
                email: action.email,
                error: null,
            };
        case "FETCH_ERROR":
            return { ...state, loading: false, error: action.error.message };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const TokenProvider = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();

    const [state, dispatch] = useReducer(authReducer, {
        token: null,
        idTokenClaims: null,
        loading: true,
        error: null,
        oid: null,
        email: null,
    });

    const fetchToken = useCallback(async () => {
        try {
            const activeAccount = accounts[0];
            if (!activeAccount) return;

            const tokenResponse = await instance.acquireTokenSilent({
                ...loginRequest,
                account: activeAccount,
            });
            console.log("Token acquired:", tokenResponse.accessToken);

            const base64Url = tokenResponse.accessToken.split(".")[1];
            if (!base64Url) throw new Error("Malformed token");
            const decodedToken = JSON.parse(atob(base64Url));
            console.log("decoded token", decodedToken)

            const expiryTime = decodedToken.exp * 1000;
            const currentTime = Date.now();
            const timeUntilExpiry = expiryTime - currentTime;

            dispatch({
                type: "FETCH_SUCCESS",
                token: tokenResponse.accessToken,
                idTokenClaims: decodedToken,
                oid: activeAccount.localAccountId,
                email: decodedToken?.emails?.[0] || activeAccount.username || null,
            });

            if (timeUntilExpiry > 5 * 60 * 1000) {
                setTimeout(fetchToken, timeUntilExpiry - 5 * 60 * 1000);
            } else {
                fetchToken();
            }
        } catch (error) {
            console.error("Failed to acquire token:", error);
            dispatch({ type: "FETCH_ERROR", error });
        }
    }, [accounts, instance]);

    useEffect(() => {
        if (inProgress === InteractionStatus.None && accounts.length > 0) {
            fetchToken();
        }
    }, [accounts, fetchToken, inProgress]);

    return (
        <TokenContext.Provider value={state}>
            {children}
        </TokenContext.Provider>
    );
};


export const useAuth = () => {
    const context = useContext(TokenContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a TokenProvider");
    }
    return context;
};

export { TokenContext };
